
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";

    @Component({
        name: "EduRgpd",
        components: {EduTitle},
    })
    export default class EduRgpd extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
