
import {Component, Vue, Prop} from 'vue-property-decorator';
import {TeamMember} from "@/types";

@Component({
    name: "EduTeamMember",
})
export default class EduTeamMember extends Vue {
    // props
    @Prop(Object) member!: TeamMember;

    // data

    // computed

    // methods

    // watch

    // hooks
}
