
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import {paragraphize} from "@/helpers/commons";
    import EduCta from "@/components/commons/EduCta.vue";

    @Component({
        name: "EduTwoColumns",
        components: {EduCta, EduTitle},
    })
    export default class EduTwoColumns extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        width: number = 0;
        height: number = 0;
        videoShown: boolean = false;

        // --- computed
        get videoWidth(): number {
            if(this.width) {
                const height = Math.ceil((this.width - 120) / 16 * 9);
                if(height <= this.height - 120) {
                    return this.width - 120;
                }
                else {
                    return Math.floor((this.height - 120) / 9 * 16);
                }
            }
            else {
                return 0;
            }
        }

        get videoHeight(): number {
            if(this.width) {
                const height = Math.ceil((this.width - 120) / 16 * 9);
                if(height > this.height - 120) {
                    return this.height - 120;
                }
                else {
                    return height;
                }
            }
            else {
                return 0;
            }
        }

        // --- methods
        showVideo() {
            this.videoShown = true;
        }

        hideVideo() {
            this.videoShown = false;
        }

        getColumnText(column: any): string {
            return paragraphize(column.texts)!;
        }
        
        // --- watch
        
        // --- hooks
        mounted() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    }
