
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Testimony} from "@/types";
import EduTestimony from "@/components/cards/EduTestimony.vue";

@Component({
    name: "EduTestimoniesLine",
    components: {EduTestimony},
})
export default class EduTestimoniesLine extends Vue {
    // props
    @Prop(Array) testimonies!: Array<Testimony>;
    @Prop(Array) colors!: Array<string>;
    @Prop(Boolean) shifted?: boolean;
    @Prop(Function) showVideo!: (video: string) => void;

    // data
    width: number = 0;
    height: number = 0;
    videoShown: boolean = false;
    currentVideo: string|null = null;

    // computed
    get lineClass(): string {
        return "EduTestimoniesLine" + (this.shifted? " shifted":"");
    }

    // methods

    // watch

    // hooks
}
