import EduOnlyTitle from "@/components/EduOnlyTitle.vue";
import EduRichContent from "@/components/EduRichContent.vue";

export default {
    _path: "/manifeste",
        _layout: "main",
        _pageLayout: "horizontalLayers",
        layers: [
        {
            _component: EduOnlyTitle,
            _name: "support",
            title: "Le manifeste",
            subtitle: "Manifeste, profession de foi… Quel que soit le nom choisi, vous trouverez ci-dessous nos réflexions, pensées, références et appuis scientifiques et convictions. " +
                "<b>En bref, tout ce qui nous anime et nous donne l’énergie nécessaire au quotidien</b>."
        },
        {
            _component: EduRichContent,
            _name: "content",
            sections: [
                {
                    level: 1,
                    title: "1. Les origines d'Edumalin",
                    blocks: [
                        "Lancé il y a 4 ans par trois professeurs, Edumalin se situe au croisement de notre expertise d’enseignants, des recherches en sciences de l’apprentissage et de leurs résultats. Articuler constamment ces données issues de la recherche et les problématiques rencontrées au quotidien par les enseignants est l’un de nos principaux « fils d’Ariane ».",
                        "Découvrez notre vision, nos engagements et notre positionnement dans le paysage de la pédagogie innovante et de l’EdTech française."
                    ]
                },
                {
                    level: 2,
                    title: "Des problématiques sans solution concluante...",
                    blocks: [
                        "Plusieurs problématiques sont à l’origine de notre démarche. Les identifier pour tenter de les dépasser est un enjeu crucial pour lever certains freins à la réussite de tous les élèves.",
                        "<b>La 1ère problématique</b> est que les élèves ne sont pas outillés <b>à priori</b> pour apprendre, souvent considéré comme allant de soi ; alors même que cette compétence du « savoir apprendre » est la clé pour s’engager de manière optimale dans l’apprentissage, et donc réussir." +
                        "<quote><span>Ramus F. (15/03/2018). Comment apprendre à apprendre? Conférence « Apprendre à l’ère du numérique », salon Eduspot, Paris.</span></quote>",
                        "Une image pour illustrer notre propos : faire des devoirs chaque soir c’est un peu comme monter un meuble IKEA. Les élèves ont besoin <b>à minima</b> des pièces qui constituent le meuble (autrement dit le cours à mémoriser, les exercices à faire …) ; mais ils ont aussi besoin du mode d’emploi qui guide la construction (autrement dit la méthode, la manière de raisonner…). Se lancer dans une telle construction sans avoir toutes les pièces et un mode d’emploi précis et explicite peut être source d’échec à court et long terme, en plus d’éroder l’estime de soi, c’est à dire la perception de sa valeur dans différents domaines.",
                        "Comme le montrent de nombreux travaux de sciences cognitives, prendre les bonnes décisions quant à la manière de réviser est une <b>capacité modulée par la métacognition (entendue comme la connaissance personnelle d’un individu sur ses capacités et fonctionnements cognitifs)</b>, un processus insuffisamment mis à l’épreuve chez les apprenants. En effet, les élèves ont tendance à privilégier des méthodes de révisions passives (relecture, surlignage, recopier leur cours…) qui favorisent la mémorisation à court terme et dont les bénéfices sur le long terme sont faibles. Ce phénomène entraine malheureusement une surestimation de la qualité de l’apprentissage, et donc un cercle vicieux qui est source d’échec chez de nombreux enfants. Pourtant, <b>la capacité à autoréguler l’apprentissage</b> est déterminante pour la réussite des élèves." +
                        "<quote><span>Dunlosky, J., & Thiede, K. W. (2013). Four cornerstones of calibration research: Why understanding students’ judgments can improve their achievement. Learning and Instruction, 24, 58–61.</span></quote>",
                        "Elle se décline entre autres en la capacité :" +
                        "<ul><li>à se fixer des objectifs en lien avec une tâche précise ;</li>" +
                        "<li>à sélectionner et utiliser des méthodes d’apprentissage efficaces et adaptées ;</li>" +
                        "<li>à vérifier explicitement si ces objectifs sont atteints avec des indices objectifs et fiables (ex : l’autoévaluation).</li></ul>",
                        "<b>Il parait donc indispensable de réduire le décalage entre les pratiques des élèves au quotidien et les résultats de la recherche sur l’optimisation des apprentissages, riche d’enseignement" +
                        "<quote><span>Brown P.C., Roediger H.L., McDaniel M.A. (2014). Mets-toi ça dans la tête ! Les stratégies d'apprentissage à la lumière des sciences cognitives. Markus Haller (nouv. éd. 2016), Genève (Suisse).</span></quote>. En suivant cette direction, il sera possible de minimiser le risque de lassitude et d’impuissance apprise" +
                        "<quote><span>Ghasemi, F. (2021). A motivational response to the inefficiency of teachers’ practices towards students with learned helplessness. Learning and Motivation, 73, 101705.</span></quote> voire de décrochage scolaire.</b>",
                        "En lien avec cette première problématique, <b>notre second axe de réflexion se focalise</b> sur la place donnée à l’évaluation formative (à savoir donner des repères en cours d’apprentissage) par rapport à l’évaluation sommative et/ou certificative (l’évaluation finale qui situe et valide un niveau). Il paraît délicat de ne mesurer très précisément les difficultés des élèves qu’au moment du contrôle final… moment où l’enseignant va changer de chapitre et donc introduire de nouvelles notions !",
                        "En l’absence <b>d’évaluation objective régulière</b> pour estimer les acquis et lacunes des élèves, leur métacognition se fonde trop souvent sur leur expérience subjective (« je pense que je sais mon cours ») et pas assez sur leur niveau réel d’acquisition (« je sais que je sais mon cours »). En complément, une des raisons de l’échec de certains élèves serait une tendance à sous-estimer la vitesse à laquelle ils risquent d’oublier les connaissances apprises. <b>L’évaluation formative utilisée en routine va permettre d’éviter ces « illusions de maitrise »</b> en favorisant une consolidation à long terme des connaissances" +
                        "<quote><span>Bjork, R. A., Dunlosky, J., & Kornell, N. (2013). Self-regulated learning: Beliefs, techniques, and illusions. Annual review of psychology, 64, 417-444.</span></quote>. Autre bénéfice envisageable au niveau pédagogique : réduire l’anxiété des élèves quant au temps d’évaluation en faisant un temps pédagogique « habituel » à part entière.",
                        "<b>Notre troisième problématique</b>, intimement liée à la seconde, réside dans le fait <b>qu’il est relativement compliqué de savoir ce que les élèves savent vraiment et de le mesurer sans que cela ne soit trop chronophage</b>. En effet, en tant qu’enseignant il est difficile d’appréhender la réelle capacité des élèves à mobiliser et s’approprier de nouvelles connaissances et compétences ; de même il n’est pas toujours facile (ni réalisable d’ailleurs !) de différencier au « cas par cas » pour s’adapter au rythme et besoins de chaque élève" +
                        "<quote><span>Cnesco. (2017). Différenciation pédagogique: Comment adapter l’enseignement pour la réussite de tous les élèves&nbsp;? Notes remises dans le cadre de la conférence de consensus du Cnesco et de l’Ifé/Ens de Lyon.</span></quote>.  Pourtant, une immense majorité des enseignants est à la recherche d’outils et bonnes pratiques pour accompagner leur pédagogie avec un grand objectif : avoir la certitude qu’elles sont efficaces.",
                        "De manière complémentaire, les compétences mises en œuvre par l’enseignant dans sa pédagogie au quotidien sont d’une grande complexité. Dans le domaine 2 du nouveau socle commun proposé en 2016 par le Ministère de l’Éducation Nationale, il est spécifié qu’il faut permettre à tous les élèves « d'apprendre à apprendre », plus précisément « savoir apprendre une leçon […] s'entraîner en choisissant les démarches adaptées aux objectifs d'apprentissage préalablement explicités ». Ces compétences sont de haut niveau et les seules formations initiales ne permettent pas toujours de les construire et les stabiliser suffisamment, en complément de l’expertise disciplinaire. Fournir l’outillage nécessaire aux enseignants est donc un socle fondamental pour qu’ils soient véritablement acteurs du développement de telles compétences, dans la classe, par et pour les apprentissages. Pour cela, il est nécessaire de déployer des moyens et des ressources concrètes pour les accompagner dans cette démarche de passeur de connaissances et de guide dans l’apprendre à apprendre" +
                        "<quote><span>Quigley, A., Muijs, D., Stringer, E. (2020). Seven recommendations for teaching self-regulated learning & metacognition. Guidance Report for Education Endowment Foundation.</span></quote>. In fine, trouver collectivement des leviers pour expliciter, motiver et engager les élèves ; mais aussi différencier et personnaliser les parcours d’apprentissage est une tâche immense et qui unit bien au-delà de la classe."
                    ]
                },
                {
                    level: 2,
                    title: "Notre engagement pour dépasser ces difficultés",
                    blocks: [
                        "Devant ces différentes problématiques, il parait donc plus que nécessaire d’identifier et de promouvoir des méthodes d’apprentissage qui favorisent véritablement la montée en compétences des élèves en maximisant la rétention à long terme. Il est également essentiel de développer la capacité d’autorégulation pour optimiser leur réussite, développer leur confiance en soi (estimation de leur capacité à faire une tâche) et leur autonomie. Et il est encore plus fondamental de construire ces propositions avec une connaissance très fine des contraintes et possibles du métier d’enseignant au quotidien. C’est la conscience et la prise en compte de tous ces niveaux d’interaction qui fait d’Edumalin un projet éducatif inédit et original.",
                        "Notre volonté est de permettre aux enseignants de compléter leur connaissance des élèves sur 2 plans :" +
                        "<ol><li>Celui des résultats : tous les travaux effectués sur notre outil génèrent des données facilitant le suivi de la classe, du groupe et de chaque élève :  nombre de travaux débutés, finis, taux de réussite de chaque travail, par matière ainsi que le temps effectif consacré à chaque tâche.  Ces données renseignant en temps réel sur le résultat du travail donnant ainsi un 1er niveau de photographie des réussites et difficultés.</li>" +
                        "<li>Celui du processus de réflexion, du raisonnement de l’élève : c’est là une des grandes spécificités et nouveautés de notre outil. Il permet aux enseignants d’examiner très précisément la manière dont l'élève a réfléchi pour donner une réponse. De visualiser les étapes du raisonnement maîtrisées ainsi que celles représentant des obstacles à son apprentissage de notions et à la résolution d'exercices.</li></ol>",
                        "<b>En somme, l’engagement d’Edumalin est de fournir une photographie en temps réel du produit, du résultat du travail mais aussi du raisonnement qui a conduit à ce résultat. Visualiser la progression des acquis sur un ensemble de connaissances devient donc possible !</b>",
                        "Pour développer le « savoir apprendre » tout au long du cursus scolaire, et plus largement tout au long de sa vie, l’équipe d’Edumalin s’est appuyée sur les possibilités offertes par le numérique en les combinant avec les résultats les plus robustes de la recherche en sciences cognitives dans <b>une démarche d’éducation fondée sur des données</b>" +
                        "<quote><span>Latimier A., Meyer S. (octobre 2017). Et si les réformes de l'éducation s’appuyaient sur les données de la science? Média The Conversation France.</span></quote>. Nous nous sommes focalisés sur le temps de <b>travail personnel des élèves, temps particulièrement fondamental dans la réussite scolaire car il présuppose un certain degré d’autonomie et la capacité d’autorégulation de la part des élèves</b>."
                    ]
                },
                {
                    level: 2,
                    title: "Notre positionnement dans l’écosystème EdTech",
                    blocks: [
                        "Au carrefour de la pédagogie et de la technologie dont nous tirons le meilleur parti, nous sommes convaincus que le développement de la compétence d’autorégulation de l’apprentissage doit être alimenté par les données issues de la recherche combinées à des données d’apprentissage en contexte. Se baser sur de simples intuitions sans fiabilité et bénéfices mesurés pour les élèves, ou pire sur de fausses croyances non fondées scientifiquement sur l’apprentissage (les fameux « neuromythes ») ne peut être satisfaisant et réellement utile" +
                        "<quote><span>Pasquinelli, E. (2012). Neuromyths: Why Do They Exist and Persist? Mind, Brain, and Education, 6(2), 89–96.</span></quote>. Au-delà des résultats solides qu’elle fournit sur le fonctionnement cérébral et le comportement humain, la démarche expérimentale des sciences cognitives est très pertinente pour comprendre comment optimiser et repenser l’apprentissage tout en laissant l’enseignant au cœur de la pédagogie" +
                        "<quote><span>Pasquinelli, E. (2013). Slippery slopes. Some considerations for favoring a good marriage between education and the science of the mind–brain–behavior, and forestalling the risks. Trends in Neuroscience and Education, 2(3–4), 111–121.</span></quote>. Car, au final, c’est bien de cela qu’il s’agit.",
                    ]
                },
                {image: "/img/illus-demarche.png", maxWidth: "500px"},
                {
                    blocks: [
                        "Dédiée à l’apprendre à apprendre et centrée sur le temps de travail personnel des élèves, notre approche pédagogique exclusive et originale propose de systématiquement combiner un contenu (« le QUOI ?») avec une méthode et une procédure explicite pour l’apprendre et le mobiliser (« le COMMENT ? »). C’est cette combinaison systématique qui va permettre aux élèves de s’approprier de nouvelles connaissances mais également de construire des stratégies pour apprendre et devenir, pas à pas et au quotidien, des apprenants tout au long de leur vie."
                    ]
                },
                {image: "/img/manifeste/manifeste-2.svg", maxWidth: "600px"},
                {
                    blocks: [
                        "C’est ce positionnement original et en phase avec les préoccupations pédagogiques des communautés éducatives (professeurs, élèves, parents, personnels de direction…) qui nous a permis de nouer des relations de confiance avec les partenaires institutionnels que sont les Académies et le ministère de l’Éducation Nationale ainsi que des collectivités territoriales."
                    ]
                },

                {
                    level: 1,
                    title: "2. Notre vision",
                    blocks: [
                        "Nous accompagnons les communautés éducatives (élèves, parents, enseignants, équipes de vie scolaire et de direction…) dans les réflexions et la mise en œuvre d’une aide au travail personnel optimisée et adaptée aux profils des élèves. Nos méthodes de travail sont exclusives et transversales. Elles sont issues de notre propre expérience d’enseignants et consolidées par les dernières découvertes en science de l’apprentissage, un champ des neurosciences cognitives grandissant. Sur ces fondations, nous construisons un outil à très forte dimension pédagogique pour tisser un lien explicite et concret entre la recherche scientifique et les pratiques pédagogiques du quotidien, c’est à dire entre le laboratoire et la classe. <b>Un maître-mot : la complémentarité</b>.",
                        "Découvrez ci-dessous notre parti-pris scientifique et notre vision pédagogique."
                    ]
                },
                {
                    level: 2,
                    title: "Une vision étayée par la recherche scientifique : les 4 piliers de l’apprentissage",
                    blocks: [
                        "Définis par le neuroscientifique Stanislas Dehaene, ces 4 piliers réunis permettraient de 1) maximiser l’ancrage et l’appropriation des connaissances sur le long terme, et 2) de minimiser l’oubli tout en réduisant les illusions de maîtrise." +
                        "<quote><span>Dehaene (05/09/2018). Cerveau : les quatre piliers de l’apprentissage. Le Dossier de L’Express</span></quote>",
                        "<b>Pilier 1 : Mobiliser son attention</b> au moment de l’encodage des connaissances, sans laquelle il est difficile d’en réaliser un traitement profond. Ce pilier nécessite un cadre propice à l’apprentissage ainsi qu’une certaine motivation, un désir d’apprendre.",
                        "<b>Pilier 2 : S’engager activement</b> en formulant des hypothèses et prédictions, en générant une réponse à un problème mais aussi en s’autoévaluant, en produisant un effort de compréhension, en se mettant à l’épreuve sont autant d’actions qui participent positivement à un ancrage profond et durable des connaissances.",
                        "<b>Pilier 3</b> : Faire l’effort de tester ses connaissances c’est aussi accepter de se mettre en difficulté et donc de faire des erreurs. Et le rôle de l’erreur est primordial dans tout développement de connaissances et compétences. Grâce à un <b>retour correctif sur nos erreurs</b>, nous pouvons remettre à jour nos modèles mentaux.",
                        "<b>Pilier 4 : Consolider des acquis par la répétition active et régulière dans le temps</b> permet de stabiliser et renforcer la trace en mémoire à long terme, avec un rôle particulier du sommeil. Mobiliser de nouvelles connaissances dans différents contextes permet non seulement leur consolidation, mais facilite également le transfert de connaissances."
                    ]
                },
                {image: "/img/manifeste/manifeste-3.svg", maxWidth: "500px"},
                {
                    blocks: [
                        "En mettant en place ces 4 piliers, il est possible de créer un véritable cercle vertueux pour favoriser le « vouloir savoir » en stimulant la curiosité et en favorisant l’apprendre à apprendre."
                    ]
                },
                {
                    level: 2,
                    title: "Une vision pédagogique originale et inédite",
                    blocks: [
                        "Plusieurs études ont mis en évidence qu’en situation d’apprentissage autonome (à la maison bien sûr mais aussi en classe, en étude, lors de Devoirs Faits, en Accompagnement Personnalisé pour les lycéens…), les élèves ont du mal à déployer des stratégies d’apprentissage réellement efficaces et à maintenir leur utilisation" +
                        "<quote><span>Bjork, R. A., Dunlosky, J., & Kornell, N. (2013). Self-regulated learning: Beliefs, techniques, and illusions. Annual review of psychology, 64, 417-444.</span></quote>. Notre équipe s’est donc centrée sur ce temps de travail personnel qui possède une structure et des exigences très spécifiques.",
                        "<b></b>L’un de nos principaux objectifs est de répondre à ces 3 questions fondamentales</b> : " +
                        "<ol><li>Comment aider les enseignants à concevoir des activités pensées spécifiquement pour ce temps de travail personnel ?</li>" +
                        "<li>Comment aider chaque élève à faire son travail au quotidien (dans ou hors de la classe, sans la présence directe de l’enseignant) sur la base de stratégies d’apprentissage simples, « guidantes » et explicites ?</li>" +
                        "<li>Comment maintenir le lien avec l’enseignant pour lui permettre de visualiser et comprendre ce qui s’est passé dans le but de favoriser la découverte puis l’automatisation d’un apprentissage autorégulé à terme ?</li></ol>",
                        "En plaçant l’enseignant au cœur de notre innovation, <b>nous avons pour objectif de favoriser la mise en place d’un cercle vertueux avec les 4 piliers de l’apprentissage sous la forme de tâches de récupération en mémoire</b>. La réalisation de ces tâches par les élèves va générer des indices fiables et objectifs pour que la métacognition fasse son travail de « chef d’orchestre ». Une conséquence attendue et mesurable : chaque élève construit jour après jour une forme d’apprentissage autorégulé."
                    ]
                },
                {
                    blocks: [
                        "Pour soutenir le développement d’une autorégulation efficace de leurs activités d’apprentissage, nous avons créé une plateforme pédagogique et numérique innovante qui intègre le modèle à 3 étapes de l’Apprentissage AutoRégulé" +
                        "<quote><span>Cosnefroy, L. (2011). L’apprentissage autorégulé. Entre cognition et motivation. Grenoble, France: Presses universitaires de Grenoble.</span></quote>" +
                        "<quote><span>Quigley, A., Muijs, D., Stringer, E. (2020). Seven recommendations for teaching self-regulated learning & metacognition. Guidance Report for Education Endowment Foundation.</span></quote>. Pour cela, Edumalin a construit des activités pédagogiques qui facilitent la mise en place d’une évaluation formative hors de la classe afin que l’enseignant puisse : " +
                        "<ol><li>Expliciter le travail à faire en précisant les buts et finalités des tâches l’apprentissage. Les élèves savent ce qu’ils doivent mémoriser et maîtriser de façon tangible ;</li>" +
                        "<li>Guider ses élèves en précisant à nouveau les procédures et stratégies à suivre pour atteindre les objectifs, le professeur peut personnaliser son guidage selon le niveau de réussite des élèves.</li>" +
                        "<li>Suivre la progression des élèves en ayant accès aux données d’apprentissage de sa classe en temps réel.</li></ol>"
                    ]
                },
                {image: "/img/illus-methode2.png", maxWidth: "900px"},
                {
                    blocks: [
                        "Grâce à notre pédagogie du « pas à pas », la méthode Edumalin joue sur plusieurs leviers :" +
                        "<ul><li><b>En maintenant un engagement actif</b> sur une tâche en orientant l’attention vers ce qui est pertinent, et en assurant un suivi plus personnalisé, les élèves sont plus motivés mais aussi plus autonomes dans leur routine d’apprentissage.</li>" +
                        "<li><b>En maintenant le lien en dehors de la classe</b>, l’enseignant peut réduire le fossé entre les élèves sur le temps de travail personnel.</li>" +
                        "<li><b>En se basant sur des données d’apprentissage fiables et objectives</b>, l’enseignant peut aller plus loin au moment du cours suivant si les acquisitions paraissent véritablement solides ou bien revenir sur des notions mal comprises. Les préparations des examens ou des évaluations sur un contenu précis sont ainsi facilitées.</li></ul>",
                        "Au-delà du soutien pédagogique pour les enseignants et du suivi quotidien du travail personnel des élèves, notre démarche vise également à offrir des points de repères pour les parents. Nous nous situons ici dans une démarche plus globale de coéducation, tendant vers une meilleure compréhension mutuelle entre tous les acteurs de la communauté éducative. La réduction des angoisses de certaines familles qui se cristallisent souvent sur le temps des devoirs à la maison est également un enjeu fort."
                    ]
                },

                {
                    level: 1,
                    title: "3. Notre outil numérique",
                    blocks: [
                        "Edumalin a conçu une plateforme d’apprentissage sous forme d’assistant pédagogique virtuel de type Chatbot appelé « AIKO ». <b>Cet assistant est là pour faciliter l’explicitation des méthodes de travail et la procédure nécessaire pour engager, guider, soutenir par des astuces et corriger le travail des élèves sur leur temps de travail personnel</b>. Notre plateforme convient particulièrement aux élèves ayant besoin de travailler à leur rythme tout en étant guidés, étayés et rassurés.",
                        "Cet outil numérique s’adresse à toutes les structures de la scolarité obligatoire (écoles, collèges, LP et LGT) mais également à toutes les structures dispensant des enseignements autour de la maîtrise langagière et du raisonnement scientifique et mathématique dans les secteurs de l'Apprentissage (CFA, etc.), de la Formation Professionnelle (en particulier les niveaux CAP et Bac Pro) et les structures de l’Économie Solidaire et Sociale (ESS)."
                    ]
                },
                {
                    level: 2,
                    title: "Qu’est-ce qu’un parcours Edumalin ?",
                    blocks: [
                        "Etant donné la robustesse de son effet comme méthode de révision, nous avons intégré des entrainements par récupération en mémoire pour consolider les nouvelles connaissances et développer l’autorégulation de l’apprentissage." +
                        "<quote><span>Carpenter, S. K., Endres, T., & Hui, L. (2020). Students’ Use of Retrieval in Self-Regulated Learning: Implications for Monitoring and Regulating Effortful Learning Experiences. Educational Psychology Review, 32(4), 10291054.</span></quote>",
                        "Chaque parcours propose 3 MODULES, selon notre triptyque « Expliciter, Guider, Suivre » :" +
                        "<ul><li><b>Le module « Mémoriser »</b> associe une synthèse, un support de cours, des informations-clés destinés à être mémorisées et une méthode pour le faire. Cette association entre le cours et le guidage (composé sur la base de stratégies de testing et d’élaboration) permet à l’élève de s’approprier les informations en comprenant véritablement les points clés du cours et les objectifs d’acquisition du contenu.</li>",
                        "<li><b>Le module « Tâche Guidée »</b> associe un énoncé d’un exercice impliquant un raisonnement complexe (lecture, compréhension de la consigne, appui sur des connaissances théoriques au bon moment, élaboration et exécution d’une démarche, d’un raisonnement…) avec un guidage sous forme d’étapes de raisonnement. C’est cette explicitation et cette association qui favorise la construction d’une pensée algorithmique commune à toutes les matières. Côté élève, ce passage à l’action sous forme décortiquée permet de mobiliser les connaissances pas à pas, à son rythme tel un pianiste débutant qui découvre les gammes… Avant de les automatiser et de les exécuter à grande vitesse.</li>" +
                        "<li><b>Le module « QCM »</b>, de conception plus classique mais notoirement efficace, vient renforcer l’ancrage par récupération et permet la vérification objective du degré d’acquisition des connaissances avec une correction personnalisée.</li></ul>",
                        "Il est possible de faire le parcours complet en réalisant les 3 modules, mais il est également possible de faire les modules de manière indépendante. Libre à l’élève de choisir le format le plus pratique pour lui, le plus adapté à sa façon de travailler. <b>Pour chaque module, la rétroaction effectuée à chaque étape, permet à l’élève de se corriger et d’avancer dans son parcours tout en faisant des aller-retours avec le cours</b>. En recevant des retours riches et centrés sur la tâche, les élèves identifient ainsi les connaissances qui ne sont pas totalement acquises et celles qui le sont, ce qui évite le risque d’illusion de maîtrise et stabilise à terme des procédures automatiques d’apprentissage." +
                        "<quote><span>Rivers, M. L. (2020). Metacognition About Practice Testing: A Review of Learners’ Beliefs, Monitoring, and Control of Test-Enhanced Learning. Educational Psychology Review.</span></quote>"
                    ]
                },
                {
                    level: 2,
                    title: "Le parcours EDUMALIN : une double solution",
                    blocks: [
                        "Notre outil est pensé à la fois :" +
                        "<ol><li>Pour l’enseignant, en lui facilitant le travail de décorticage et d’explicitation des objectifs du cours avec des modules d’entrainements préconçus. Expliciter les méthodes de travail peut se faire de 2 manières : soit en créant ses propres parcours, soit en utilisant ou modifiant sans restriction les parcours de nos bibliothèques. L’enseignant peut affecter soit un module Edumalin, soit un parcours, aussi bien à un élève qu’à un groupe d’élèves ou une classe entière. C’est lui qui est maître du guidage et du suivi grâce à l’accès aux données d’apprentissage en temps réel.  Ces données le renseignent sur 2 dimensions :" +
                        "<ul><li>Le résultat avec un taux de réussite à plusieurs échelles (classe, groupe, élève)</li>" +
                        "<li>Le processus avec une visualisation là aussi à différentes échelles (élève, classe) de la démarche de l’élève. Ces visualisations permettent de comprendre pourquoi et à quelle étape un ou plusieurs élèves rencontrent des difficultés ce qui facilite les rétroactions extrêmement fines et adaptées à ces difficultés.</li></ul></li>" +
                        "<li>Pour l’élève, qui entre dans une forme d’autonomie tout en étant véritablement pris par la main. Dans son interface, il a accès à l'ensemble des parcours et modules de son niveau et peut donc choisir de s’entraîner seul ou bien sur affectation volontaire de son enseignant ou accompagnant sur le temps de travail personnel. Grâce à AIKO, l’élève peut mieux s’organiser au quotidien et mobiliser rapidement les connaissances vues en cours sans perdre de temps à sélectionner lui-même les méthodes pour synthétiser et mémoriser les contenus en vue de préparer ses évaluations.</li></ol>",
                        "Le numérique est ainsi mis à disposition de la pédagogie pour renforcer et optimiser la relation entre l’enseignant et l’élève de manière ciblée et co-construite.",
                        "<b>La force de notre outil réside également dans la visualisation des données d’apprentissage en temps réel aux échelles macro et micro</b>. Cette visualisation simple, intuitive, et détaillée des résultats au sein d’un parcours permet de combler le point aveugle du professeur entre deux cours. Il peut ainsi savoir :" +
                        "<ul><li>Combien de temps un élève, une classe passe sur ses devoirs, le taux de rendu des devoirs…</li>" +
                        "<li>Si les élèves ont plus ou moins bien réussi le travail.</li>" +
                        "<li>A quel endroit, exactement, les élèves ont rencontré des difficultés dans le raisonnement.</li></ul>",
                        "Sur la base de ces données, l’enseignant peut ajuster et corriger de manière très personnalisée les exercices avec des commentaires écrits et oraux. Il peut également apporter des clés de guidage pour remettre l’élève sur le bon chemin, en explicitant à nouveau les objectifs ou en reformulant certaines connaissances qui posent problème. L’enjeu est bien de piloter la séquence en cours à la lumière de ce qui doit être appris (le curriculum formel) mais également de ce qui est réellement appris (le curriculum réel). Travailler sur la réduction du delta entre ces 2 curriculums est une des possibilités majeures offerte par Edumalin."
                    ]
                },

                {
                    level: 1,
                    title: "4. De premiers résultats quant à l’efficacité de notre outil",
                },
                {
                    level: 2,
                    title: "Un taux de réussite élevé...",
                    blocks: [
                        "Les données d’apprentissage recueillies en un semestre (09/20 – 02/21) sur notre outil montrent que sur plus de 20 000 parcours réalisés par les élèves <b>le taux de réussite se situe vers 77% (tous niveaux et disciplines confondus)</b>. Ce taux serait le reflet d’une bonne calibration de la difficulté des entrainements, avec un taux de réussite important des apprenants et en même temps une marge de progression non négligeable pour attendre de leur part un effort bénéfique pour maintenir leur niveau, progresser, et rester motivés" +
                        "<quote><span>Baranes, A. F., Oudeyer, P. Y., & Gottlieb, J. (2014). The effects of task difficulty, novelty and the size of the search space on intrinsically motivated exploration. Frontiers in neuroscience, 8, 317.</span></quote>. Même si à ce jour aucune étude scientifique n’a démontré clairement l’existence d’un optimum pour calibrer le taux de difficulté d’un entrainement, des simulations avec des algorithmes d’apprentissage ont récemment montré qu’un taux d’erreur autour de 16% accompagné de rétroaction favoriserait la réussite" +
                        "<quote><span>Wilson, R.C., Shenhav, A., Straccia, M. et al. (2019). The Eighty Five Percent Rule for optimal learning. Nat Commun 10, 4646.</span></quote>. Ce qui démontre que commettre des erreurs est nécessaire, mais dans une certaine mesure ! Il est important de noter que cet optimum est sans doute dépendant de nombreux facteurs et qu’il est délicat de vouloir le généraliser. L’estimer permet quand même de donner un ordre de grandeur. <b>Notre plateforme aiderait justement les enseignants à trouver le bon dosage de difficulté pour emmener leurs élèves plus loin, pas à pas, en les guidant avec des retours correctifs ciblés. L’un des bénéfices envisagés est également d’amener les élèves à construire une forme de motivation par la réussite</b>." +
                        "<quote><span>Masson, S. (février 2021). Comprendre le cerveau pour favoriser la motivation. Journées de la Persévérance scolaire, Webinaire PRECA.</span></quote>"
                    ]
                },
                {
                    level: 2,
                    title: "...qui se maintient de manière assez homogène",
                    blocks: [
                        "Nous avons recueilli les résultats ci-dessous sur la période 09/20-02/21, soit un semestre, détaillés en fonction des matières travaillées sur Edumalin (niveaux collèges, LP et LGT) :"
                    ]
                },
                {
                    table: {
                        columns: [
                            "Matière", "Maths", "Français", "Histoire", "Géographie", "Anglais", "Mat. Pro", "Sciences"
                        ],
                        rows: [
                            ["Taux de réussite", "76,5%", "77,1%", "74,8%", "72,7%", "82,1%", "78,5%", "79,8%"]
                        ]
                    }
                },
                {
                    blocks: [
                        "En fonction des contextes d’enseignement, et sur un minimum de 400 sessions de travail sur notre outil, les taux de réussite varient peu :"
                    ]
                },
                {
                    table: {
                        columns: [
                            "Type de structure", "Collège C.", "LP V.H", "Collège B.V", "Collège L.T", "Collège G.R", "LP A."
                        ],
                        rows: [
                            ["Lieu d'implantation", "Paris Centre CSP+", "Versailles - 95 / Formations tertiares", "Paris périphérie - REP", "Dordogne, petite structure", "Paris REP+", "Bordeaux - 64 (coiffure, esthétique...)"],
                            ["Taux de réussite", "80%", "74%", "70%", "74%", "75%", "80%"]
                        ]
                    }
                },
                {
                    level: 2,
                    title: "Des mesures très encourageantes en situation :",
                    blocks: [
                        "Une première expérimentation mesurée sur l’usage d’Edumalin a été réalisée avec des élèves (N=30) et des enseignants (N=7) au sein d’un lycée professionnel entre fin 2019 et début 2020 (4 mois). L’évaluation du ressenti des élèves montre des résultats très positifs.",
                        "<b>Globalement, les élèves perçoivent un gain non négligeable après plusieurs semaines d’utilisation des Parcours Edumalin</b>. Ils expriment le fait que l’application les a aidés à s’entraîner. Plus précisément, nous avons synthétisé leur ressenti sur deux paramètres : utilité et bénéfice perçus de l’outil ; et adhésion à l’utilisation de l’outil (voir infographie ci-dessous).",
                        "Du point de vue des enseignants, le constat principal est que l’outil permet de développer l’autonomie des élèves et d’assurer un meilleur suivi du travail personnel puisqu’il leur permet d’avoir un retour détaillé sur la progression et les raisonnements des élèves.",
                        "A l’unanimité, ils ont jugé facile de comprendre le fonctionnement de l’outil, avec un coût de prise en main ressenti comme minime. <b>L’utilisation d’AIKO est jugée également utile pour guider l’enseignant aussi bien dans la préparation des cours, que dans le travail en classe, mais aussi pour un bilan après le cours grâce aux tableaux de bord pour visualiser les données d’apprentissage</b>. La possibilité de mutualiser des parcours créés par les enseignants mais aussi d’avoir des rythmes différenciés selon le niveau des élèves sont particulièrement appréciés. Enfin, tout comme leurs élèves, les enseignants interrogés désirent continuer de se servir de notre outil et tous le recommanderaient à leurs collègues.",
                        "<b>Et depuis</b> : 12 mois après cette mesure, les tendances et usages se sont amplifiés voire développés dans cet établissement ! Trois sessions de formation entre pairs ont été organisées en interne et les contenus se sont enrichis pour des matières non traitées par Edumalin jusqu’à présent (matières professionnelles, etc.…), ce qui semble consolider l’intérêt de notre approche et de nos méthodes transversales déclinées dans les matières. Enfin, certains enseignants témoignent également d’une évolution de leur pratique pédagogique (même sans forcément utiliser l’outil) en s’appuyant beaucoup plus régulièrement sur les principes d’explicitation et de guidage fondamentaux de notre approche pédagogique."
                    ]
                },
                {
                    galleries: [
                        {columns: 3, pictures: ["/img/usages/cas-1-1.png", "/img/usages/cas-1-2.png", "/img/usages/cas-1-3.png"]},
                        {columns: 3, pictures: [null, "/img/usages/cas-1-legend.png"]}
                    ]
                },
                {
                    level: 2,
                    title: "Vers des choix pédagogiques et des politiques publiques d’éducation pilotés par la donnée (approche datadriven) :",
                    blocks: [
                        "Au niveau micro, l’un des enjeux d’Edumalin est donc de permettre aux enseignants de mieux cerner les points de blocage dans l’acquisition des savoirs de chaque élève pour mieux les guider. Cet enjeu doit leur permettre de sortir du simple constat pour aller plus loin en réfléchissant à des remédiations plus fines, à la fois individuellement et collectivement.",
                        "Mais les engagements d’Edumalin vont donc au-delà de cette échelle micro. L’un des enjeux fondamentaux en termes d’optimisation et d’agilité se situe dans la capacité de nos organisations à : 1) identifier les difficultés réelles d’apprentissage et de maîtrise des connaissances des élèves ; 2) y répondre de manière agile, rapide et adaptée, en appui sur les communautés éducatives et scientifiques. <b>Notre outil a également été conçu pour apporter des données pertinentes et d’un genre nouveau afin d’éclairer : le pilotage pédagogique à l’échelle d’un établissement scolaire mais aussi le pilotage à l’échelle d’une Académie</b> (en termes de réussite et d’acquisitions disciplinaires). A différents échelons, cela permettra d’inspirer et nourrir les politiques publiques à l’échelle d’une commune, un département ou une région, mais aussi dans une dimension nationale. Notre ambition est de rendre explicite et accessible ce qui ne l’est pas suffisamment à l’échelle macroscopique grâce à la visualisation des données agrégées sur plusieurs établissements.",
                        "Pour illustrer notre propos, voici un exemple de mesures effectuées sur un échantillon de 700 élèves de niveau 6ème au sein de l’Académie de Paris (semestre 09/20-02/21) dans le cadre d’un module de type « Mémoriser » en Maths (à savoir leçon + guidage). Dans ce cas précis, la 1ère partie présente les « Définitions » importantes de ce chapitre sur les aires avec un taux d’acquisition des connaissances de 65,2% ; la 2nde partie de la leçon parle des « Comparaisons d’aires » avec 71,7% d’acquis ; la 3ème partie s’intéresse aux différents « Calculs d’aires » avec 55,5% d’acquis, et enfin la 4ème partie traite des « Tableau de conversion des aires » avec 26,5% d’acquis.",
                        "Nous obtenons une « photographie » en temps réel des acquisitions qui montre que sur cet échantillon d’élèves de 6ème, les difficultés sur le chapitre des Aires se cristallisent sur la partie « Tableau de conversion des aires ». <b>Ces données agrégées à l’échelle macro fournissent des indications précieuses à la fois aux enseignants sur un territoire donné, mais également aux inspecteurs et acteurs de la formation des enseignants</b>. A la lumière de ces données, et dans une logique de personnalisation des apprentissages, il est ainsi envisageable de réfléchir à la mise en place de temps dédiés à la remédiation pleinement adaptés aux difficultés identifiées ; et ce à différentes échelles (établissement scolaire, circonscription…). Dans la mesure où Edumalin est déployé, cette photographie est réalisable quelle que soit l’échelle de temps, la matière, le niveau ou la tâche à réaliser pour mobiliser les nouvelles connaissances."
                    ]
                },
                {image: "/img/manifeste/manifeste-5.png", maxWidth: "800px"},
                {
                    blocks: [
                        "Légende : les pourcentages dans les différentes portions en couleur correspondent à :" +
                        "<ul><li>Une très bonne maîtrise des connaissances en bleu</li>" +
                        "<li>Une maîtrise satisfaisante en vert</li>" +
                        "<li>Une maîtrise moyenne en violet</li>" +
                        "<li>Une maîtrise insuffisante en rouge</li>" +
                        "<li>Une absence de réponse en gris</li></ul>",
                        "Ces différents paliers de maîtrise sont indexés sur le niveau de maîtrise du Brevet (fin de cycle 4)." +
                        "<quote><span>Modalités d'attribution du diplôme national du brevet, Site Eduscol.</span></quote>"
                    ]
                },

                {
                    level: 1,
                    title: "Liens vers les références citées",
                    links: [
                        {
                            text: "Ramus F. (2018). Comment apprendre à apprendre ? Conférence « Apprendre à l’ère du numérique », salon Eduspot, Paris.",
                            link: "https://lettres-histoire.ac-versailles.fr/spip.php?article1358"
                        },
                        {
                            text: "Dunlosky, J., & Thiede, K. W. (2013). Four cornerstones of calibration research: Why understanding students’ judgments can improve their achievement. Learning and Instruction, 24, 58–61.",
                            link: "https://www.sciencedirect.com/science/article/abs/pii/S0959475212000345"
                        },
                        {
                            text: "Brown P.C., Roediger H.L., McDaniel M.A. (2014). Mets-toi ça dans la tête ! Les stratégies d'apprentissage à la lumière des sciences cognitives. Markus Haller (nouv. éd. 2016), Genève (Suisse).",
                            link: "https://www.decitre.fr/livres/mets-toi-ca-dans-la-tete-les-strategies-d-apprentissage-a-la-lumiere-des-sciences-cognitives-9782940427284.html"
                        },
                        {
                            text: "Ghasemi, F. (2021). A motivational response to the inefficiency of teachers’ practices towards students with learned helplessness. Learning and Motivation, 73, 101705.",
                            link: "https://www.sciencedirect.com/science/article/abs/pii/S0023969020301983"
                        },
                        {
                            text: "Bjork, R. A., Dunlosky, J., & Kornell, N. (2013). Self-regulated learning: Beliefs, techniques, and illusions. Annual review of psychology, 64, 417-444.",
                            link: "https://sites.williams.edu/nk2/files/2011/08/Bjork.Dunlosky.Kornell.2013.pdf"
                        },
                        {
                            text: "Cnesco. (2017). Différenciation pédagogique: Comment adapter l’enseignement pour la réussite de tous les élèves ? Notes remises dans le cadre de la conférence de consensus du Cnesco et de l’Ifé/Ens de Lyon.",
                            link: "http://www.cnesco.fr/fr/differenciation-pedagogique/"
                        },
                        {
                            text: "Quigley, A., Muijs, D., Stringer, E. (2020). Seven recommendations for teaching self-regulated learning & metacognition. Guidance Report for Education Endowment Foundation.",
                            link: "https://educationendowmentfoundation.org.uk/tools/guidance-reports/metacognition-and-self-regulated-learning/"
                        },
                        {
                            text: "Latimier A., Meyer S. (10/2017). Et si les réformes de l'éducation s’appuyaient sur les données de la science? Média The Conversation France.",
                            link: "https://theconversation.com/et-si-les-reformes-de-leducation-sappuyaient-sur-les-donnees-de-la-science-79713"
                        },
                        {
                            text: "Pasquinelli, E. (2012). Neuromyths: Why Do They Exist and Persist? Mind, Brain, and Education, 6(2), 89–96.",
                            link: "https://onlinelibrary.wiley.com/doi/full/10.1111/j.1751-228X.2012.01141.x"
                        },
                        {
                            text: "Pasquinelli, E. (2013). Slippery slopes. Some considerations for favoring a good marriage between education and the science of the mind–brain–behavior, and forestalling the risks. Trends in Neuroscience and Education, 2(3–4), 111–121.",
                            link: "https://neuroeduca804.files.wordpress.com/2017/11/slippery-slopes-some-considerations-for-favoring-a-good-_2013_trends-in-neu.pdf"
                        },
                        {
                            text: "Dehaene (05/09/2018). Cerveau : les quatre piliers de l’apprentissage. Le Dossier de L’Express.",
                            link: "https://www.lexpress.fr/actualite/societe/les-quatre-piliers-de-l-apprentissage_2031330.html"
                        },
                        {
                            text: "Carpenter, S. K., Endres, T., & Hui, L. (2020). Students’ Use of Retrieval in Self-Regulated Learning: Implications for Monitoring and Regulating Effortful Learning Experiences. Educational Psychology Review, 32(4), 10291054.",
                            link: "https://www.teachertoolkit.co.uk/wp-content/uploads/2020/09/Carpenter2020_Article_StudentsUseOfRetrievalInSelf-R.pdf"
                        },
                        {
                            text: "Rivers, M. L. (2020). Metacognition About Practice Testing: A Review of Learners’ Beliefs,Monitoring, and Control of Test-Enhanced Learning. Educational Psychology Review.",
                            link: "https://www.researchgate.net/profile/Michelle-Rivers/publication/344891844_Metacognition_About_Practice_Testing_a_Review_of_Learners%27_Beliefs_Monitoring_and_Control_of_Test-Enhanced_Learning/links/5fa82484a6fdcc062420055d/Metacognition-About-Pra"
                        },
                        {
                            text: "Baranes, A. F., Oudeyer, P. Y., & Gottlieb, J. (2014). The effects of task difficulty, novelty and the size of the search space on intrinsically motivated exploration. Frontiers in neuroscience, 8, 317.",
                            link: "https://core.ac.uk/download/pdf/82834117.pdf"
                        },
                        {
                            text: "Wilson, R.C., Shenhav, A., Straccia, M. et al. (2019). The Eighty Five Percent Rule for optimal learning. Nat Commun 10, 4646.",
                            link: "https://www.biorxiv.org/content/biorxiv/early/2018/01/27/255182.full.pdf"
                        },
                        {
                            text: "Masson, S. (février 2021). Comprendre le cerveau pour favoriser la motivation. Journées de la Persévérance scolaire, Webinaire PRECA.",
                            link: "https://vimeo.com/512912366"
                        },
                        {
                            text: "Modalités d'attribution du diplôme national du brevet, Site Eduscol.",
                            link: "https://eduscol.education.fr/713/modalites-d-attribution-du-diplome-national-du-brevet"
                        }
                    ]
                },
                {
                    level: 1,
                    title: "Autres ressources",
                    links: [
                        {
                            text: "Latimier, A. (mai 2020). Comment « Mieux Apprendre » pour consolider les apprentissages scolaires ? Magazine CANOPÉ (Réseau CANOPÉ).",
                            link: "https://www.reseau-canope.fr/nouveaux-programmes/magazine/developpement-cognitif/comment-mieux-apprendre-pour-consolider-les-apprentissages-scolaires.html"
                        },
                        {
                            text: "Travaux du Groupe de Travail “Métacognition et Confiance en soi” du CSEN.",
                            link: "https://www.reseau-canope.fr/conseil-scientifique-de-leducation-nationale-site-officiel/groupes-de-travail/gt-5-metacognition-et-confiance-en-soi.html"
                        },
                        {
                            text: "Meyer, S. (03/2021) Les incontournables (selon moi) pour intégrer les sciences cognitives dans sa pratique pédagogique. Medium France.",
                            link: "https://svtln-meyer.medium.com/les-incontournables-selon-moi-pour-int%C3%A9grer-les-sciences-cognitives-dans-sa-pratique-p%C3%A9dagogique-ed84a7827fd6"
                        },
                        {
                            text: "Penser autrement la formation et l'enseignement. Programme Apprenance.",
                            link: "https://apprenance-grenoble.fr/"
                        }
                    ]
                }
            ]
        }
    ]
};
