
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import {paragraphize} from "@/helpers/commons";

 
    type Section = {
        backgroundImage: string,
        width: string,
        height: string
    }

    @Component({
        name: "EduRichContent",
    })
    export default class EduRichContent extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data

        // --- computed

        // --- methods
        sectionBlocks(section: any): string|null {
            if(section.blocks || section.block) {
                return paragraphize(section.blocks || section.block);
            }
            else {
                return null;
            }
        }

        getRowCol(row: any): string {
            switch(row.columns) {
                case 1:
                    return "col-desktop-12 col-mobile-12";
                case 2:
                    return "col-desktop-6 col-mobile-12";
                case 3:
                    return "col-desktop-4 col-mobile-12";
                case 4:
                    return "col-desktop-3 col-tablet-6 col-mobile-12";
                case 5:
                case 6:
                    return "col-desktop-2 col-tablet-4 col-mobile-6";
                default:
                    return "col-desktop-12 col-mobile-12";
            }
        }

        imageStyle(section: {maxWidth: string}): {maxWidth: string}|null {
            if(section.maxWidth) {
                return {
                    maxWidth: section.maxWidth
                };
            }
            else {
                return null;
            }
        }

        iconStyle(section: any): Section|null {
            if(section.icon) {
                return {
                    backgroundImage: "url('" + section.icon + "')",
                    width: section.width,
                    height: section.height
                };
            }
            else {
                return null;
            }
        }
        titleStyle(section: {size: string}): {fontSize: string}|null {
            if(section.size) {
                return {
                    fontSize: section.size,
                }
            } else {
                return null;
            }
        }
        customClass(section: any): {customClass: string}|null {
            if(section.customClass) {
                return section.customClass
            } else {
                return null;
            }
        }

        // --- watch

        // --- hooks
    }
