
import {Component, Vue, Prop} from 'vue-property-decorator';
import {paragraphize} from "@/helpers/commons";

@Component({
    name: "EduDate",
})
export default class EduDate extends Vue {
    // props
    @Prop(String) date?: string;
    @Prop(String) color?: string;
    @Prop(String) title!: string;
    @Prop([String, Array]) text!: string|Array<string>;

    // data

    // computed
    get theText(): string {
        return paragraphize(this.text)!;
    }

    get theColor(): string {
        return this.color? this.color:"red";
    }

    get mainClass(): string {
        return "EduDate " + this.theColor;
    }

    get paragraphsClass(): string {
        return "paragraphs " + this.theColor;
    }

    // methods

    // watch

    // hooks
}
