import Vue from "vue";


export function paragraphize(text: string|Array<string>) : string|null {
    if(text) {
        let atext: Array<string> = Array.isArray(text)? text:text.split("\n");
        return atext.map((p: string) => {
            return "<p>" + p + "</p>";
        }).join("");
    }
    else {
        return null;
    }
}

export function doAction(action: any, component: Vue & { readonly $route: any; readonly $router: any; }) {
    if(action.event) {
        component.cms.emitEvent(action.event, action.data);
    }
    else if(action.scrollTo) {
        component.$router.push({name: component.$route.name!, params: component.$route.params, query: component.$route.query, hash: "#" + action.scrollTo});
    }
}

export function emptyString(data: string) : boolean {
    return (data === undefined) || (data === null) || (data.trim().length === 0);
}

export function notset(data: any) : boolean {
    return (data === undefined) || (data === null);
}
