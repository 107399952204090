
export default [
    // -------------------------------- ANCIENS

    {
        category: "revoir un webinaire",
        old: true,
        title: "Tout ce qu'il faut savoir sur Edumalin pour vous lancer vos 1ers usages",
        text: "Découvrez dans ce webinaire les essentiels d'Edumalin :\n" +
            "<ul><li>La philosophie</li>" +
            "<li>Où trouver Edumalin dans son ENT ?</li>" +
            "<li>Les bibliothèques par matières</li>" +
            "<li>Personnaliser les parcours Edumalin et créer</li>" +
            "<li>Les outils de suivi et de visualisation des données</li></ul>",
        link: "https://youtu.be/n_X0CKMuHgQ",
        cta: "Revoir"
    },

    /* webinaires passés sans replay.
    {
        category: "webinaire",
        title: "Mes tout premiers pas sur Edumalin - Spécial pédagogue",
        date: "Mercredi 17 Novembre 2021 à 13h00",
        text: "Venez découvrir comment : \n" +
            "<ul><li>Accéder à Edumalin</li>" +
            "<li>Se connecter</li>" +
            "<li>Trouver un contenu</li>" +
            "<li>Donner du travail à vos élèves</li></ul>\n" +
            "… et obtenir des réponses à toutes vos questions !",
        link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTkwYTYxOWMtZDhjYi00NGIxLTk4ZTQtNzgzNjJjOTRkZmUy%40thread.v2/0?context=%7b%22Tid%22%3a%226e17bcfb-bdfc-4dbb-b783-a6063b4f07ae%22%2c%22Oid%22%3a%22bc014616-270f-44a4-9278-069c1ce99190%22%7d",
        cta: "Participer",
        picture: "/img/webinaries/webinaire01.png"
    },
    {
        category: "webinaire",
        title: "Mes tout premiers pas sur Edumalin - Spécial élève",
        date: "Lundi 22 Novembre 2021 à 17h45",
        text: "Viens découvrir comment : \n" +
            "<ul><li>Accéder à Edumalin</li>" +
            "<li>Faire le travail donné par tes professeurs</li>" +
            "<li>Trouver un contenu pour t'entraîner tout seul</li>" +
            "<li>Suivre tes résultats</li></ul>\n" +
            "… et obtenir des réponses à toutes tes questions !",
        link: "https://cutt.ly/BRP79H8",
        cta: "Participer",
        picture: "/img/webinaries/webinaire02.png"
    },
    {
        category: "webinaire",
        title: "Personnalisez un module Mémoriser !",
        date: "Mercredi 1er Décembre 2021 à 13h00",
        text: "Venez découvrir comment : \n" +
            "<ul><li>Tester nos modules Mémoriser</li>" +
            "<li>Personnaliser le guidage</li>" +
            "<li>Personnaliser le support</li></ul>\n" +
            "… pour répondre au mieux à vos besoins pédagogiques !",
        link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGFmMDI3ZGItZWFiMS00YzM5LWFiZmMtN2ZhMWY0NGQ4YjU3%40thread.v2/0?context=%7b%22Tid%22%3a%226e17bcfb-bdfc-4dbb-b783-a6063b4f07ae%22%2c%22Oid%22%3a%22bc014616-270f-44a4-9278-069c1ce99190%22%7d",
        cta: "Participer",
        picture: "/img/webinaries/webinaire03.png"
    },
    {
        category: "webinaire",
        title: "Personnalisez un module Tâche Guidée !",
        date: "Mercredi 8 Décembre 2021 à 13h00",
        text: "Venez découvrir comment : \n" +
            "<ul><li>Tester nos Tâches Guidées</li>" +
            "<li>Personnaliser le guidage</li>" +
            "<li>Personnaliser le support</li></ul>\n" +
            "… pour répondre au mieux à vos besoins pédagogiques !",
        link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjIyNDZkNWItNWQ4YS00ZWY2LTg0MTktM2U5ODhjNjFhOTU1%40thread.v2/0?context=%7b%22Tid%22%3a%226e17bcfb-bdfc-4dbb-b783-a6063b4f07ae%22%2c%22Oid%22%3a%22bc014616-270f-44a4-9278-069c1ce99190%22%7d",
        cta: "Participer",
        picture: "/img/webinaries/webinaire04.png"
    },
    {
        category: "webinaire",
        title: "Personnalisez un module QCM !",
        date: "Lundi 13 Décembre 2021 à 17h45",
        text: "Venez découvrir comment : \n" +
            "<ul><li>Tester nos modules QCM</li>" +
            "<li>Personnaliser le guidage</li>" +
            "<li>Personnaliser le support</li></ul>\n" +
            "… pour répondre au mieux à vos besoins pédagogiques !",
        link: "https://cutt.ly/xRP5lMJ",
        cta: "Participer",
        picture: "/img/webinaries/webinaire05.png"
    },
    {
        category: "webinaire",
        title: "Créez un module Mémoriser !",
        date: "Lundi 10 Janvier 2022 à 17h45",
        text: "Venez découvrir comment : \n" +
            "<ul><li>Utiliser nos différentes typologies de questions</li>" +
            "<li>Mettre vos propres contenus sur la plateforme</li>" +
            "<li>Suivre le travail de vos élèves</li></ul>\n" +
            "… pour créer au plus proche de vos besoins pédagogiques !",
        link: "https://cutt.ly/XRP56vS",
        cta: "Participer",
        picture: "/img/webinaries/webinaire06.png"
    },
    */
];