
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduCard from "@/components/commons/EduCard.vue";
    import EduTitle from "@/components/commons/EduTitle.vue";

    @Component({
        name: "EduFaqs",
        components: {EduTitle, EduCard},
    })
    export default class EduFaqs extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        color = ["red", "blue", "green"];
        defaultLayout = [[1, 2], [2, 1]];
        
        // --- computed
        get layout(): Array<Array<number>> {
            return (Array.isArray(this.context.rowLayout) && (this.context.rowLayout.length >= 1))? this.context.rowLayout:this.defaultLayout;
        }

        get faqs(): Array<any> {
            if(this.context.faqs) {
                const faqs = this.cms.getData(this.context.faqs);
                if(faqs) {
                    let i = 0;
                    let j = 0;
                    let rows: Array<Array<any>> = [];
                    let currentRow: Array<any> = [];
                    faqs.forEach((faq: any, idx: number) => {
                        const col = this.layout[i][j] * 4;

                        currentRow.push({
                            ...faq,
                            colClass: "col-tablet-" + (col) + " col-mobile-12",
                            color: this.color[idx % 3]
                        });

                        j++;
                        if(j >= this.layout[i].length) {
                            i = (i + 1) % this.layout.length;
                            j = 0;
                            rows.push(currentRow);
                            currentRow = [];
                        }
                    });
                    if(currentRow.length > 0) {
                        rows.push(currentRow);
                    }
                    console.log("rows: ", rows);
                    return rows;
                }

            }

            return [];
        }

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
