
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduDate from "@/components/cards/EduDate.vue";
    import EduTitle from "@/components/commons/EduTitle.vue";

    @Component({
        name: "EduDates",
        components: {EduTitle, EduDate},
    })
    export default class EduDates extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
