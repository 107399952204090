
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduScreen from "@/components/cards/EduScreen.vue";

    @Component({
        name: "EduScreens",
        components: {EduScreen},
    })
    export default class EduScreens extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get customMarginTop(): any {
            return {
                marginTop: this.context.customMarginTop
            };
        }
        // --- methods
        
        // --- watch
        
        // --- hooks
    }
