
import { Component, Vue, Prop } from "vue-property-decorator";
import { CMSBaseComp, CMSComponent } from "@snark/cms";
import EduTitle from "@/components/commons/EduTitle.vue";
import EduCta from "@/components/commons/EduCta.vue";

@Component({
  name: "EduVideo",
  components: { EduCta, EduTitle },
})
export default class EduVideo extends Vue implements CMSBaseComp {
  // --- props
  @Prop(Object) context!: CMSComponent;

  // --- data
  width: number = 0;
  height: number = 0;

  // --- computed
  get videoWidth(): number {
    if (this.width) {
      const height = Math.ceil(((this.width - 120) / 16) * 9);
      if (height <= this.height - 120) {
        return this.width - 120;
      } else {
        return Math.floor(((this.height - 120) / 9) * 16);
      }
    } else {
      return 0;
    }
  }

  get videoHeight(): number {
    if (this.width) {
      const height = Math.ceil(((this.width - 120) / 16) * 9);
      if (height > this.height - 120) {
        return this.height - 120;
      } else {
        return height;
      }
    } else {
      return 0;
    }
  }
  // --- methods

  // --- watch

  // --- hooks
  mounted() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }
}
