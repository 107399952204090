
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";

    @Component({
        name: "EduFooter",
    })
    export default class EduFooter extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data

        // --- computed

        // --- methods

        // --- watch

        // --- hooks
    }
