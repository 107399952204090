
    import Vue from "vue";
    import {Component, Prop, Watch} from 'vue-property-decorator';
    import CookieLaw from 'vue-cookie-law'

    @Component({
        name: "App",
        components: {CookieLaw}
    })
    export default class App extends Vue {
        // data
        // methods
        // hooks
        // mounted
    }
