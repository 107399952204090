
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Testimony} from "@/types";
import {paragraphize} from "@/helpers/commons";
import EduCta from "@/components/commons/EduCta.vue";

@Component({
    name: "EduTestimony",
    components: {EduCta},
})
export default class EduTestimony extends Vue {
    // props
    @Prop(Object) testimony!: Testimony;
    @Prop(String) color!: string;
    @Prop(Function) showVideo!: (video: string) => void;

    // data

    // computed
    get text(): string {
        return paragraphize(this.testimony.text)!;
    }

    get mainClass(): string {
        return 'EduTestimony ' + this.color
            + (this.testimony.video? ' withVideo':'')
            + (this.testimony.picture? ' withPicture':'');
    }

    get pictureStyle(): any {
        return {
            backgroundImage: "url('" + this.testimony.picture + "')"
        };
    }


    // --- methods

    // --- watch

    // --- hooks
}
