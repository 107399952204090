
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import {paragraphize} from "@/helpers/commons";

    @Component({
        name: "EduUseCasesFalc",
        components: {EduTitle},
    })
    export default class EduUseCases extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        color = ["blue", "red", "green"];
        
        // --- computed

        // --- methods
        getCaseText(cas: any): string {
            return paragraphize(cas.texts)!;
        }

        getRowCol(row: any): string {
            switch(row.columns) {
                case 1:
                    return "col-desktop-12 col-mobile-12";
                case 2:
                    return "col-desktop-6 col-mobile-12";
                case 3:
                    return "col-desktop-4 col-mobile-12";
                case 4:
                    return "col-desktop-3 col-tablet-6 col-mobile-12";
                case 5:
                case 6:
                    return "col-desktop-2 col-tablet-4 col-mobile-6";
                default:
                    return "col-desktop-12 col-mobile-12";
            }
        }
        
        // --- watch
        
        // --- hooks
    }
