
import {Component, Vue, Prop} from 'vue-property-decorator';
import EduLoader from "@/components/commons/EduLoader.vue";

@Component({
    name: "EduCta",
    components: {EduLoader},
})
export default class EduCta extends Vue {
    // props
    @Prop(String) label!: string;
    @Prop(String) color!: string;
    @Prop([String, Object]) link?: any;
    @Prop(String) mode?: string;
    @Prop(String) download?: string;
    @Prop(String) downloadName?: string;
    @Prop(Boolean) submit?: boolean;
    @Prop(Function) waitingHandler?: () => Promise<any>;
    @Prop(String) target?: string

    // data
    waiting = false;

    // computed
    get binds(): any {
        let binds:any = {};
        if(this.link) {
            if((typeof this.link === "string") && this.link.startsWith("http")) {
                binds.is = "a";
                binds.href= this.link;
                if (this.target) {
                    binds.target = this.target;
                }
            }
            else {
                console.log("LINK: ", this.link);
                binds.is = "router-link";
                binds.to = this.link;
            }
        }
        else if(this.download) {
            binds.is = "a";
            binds.href = this.download;
            binds.download = this.downloadName? this.downloadName : '';
        }
        else {
            binds.is = "a";
        }

        binds.class = "EduCta " + this.color + " " + (this.mode? this.mode:"big");

        return binds;
    }

    get waitingClass(): string {
        return "EduCta " + this.color + " " + (this.mode? this.mode:"big") + " waiting";
    }

    // methods
    async click(event: Event) {
        if(!this.download && !this.link) {
            event.preventDefault();
            if(this.waitingHandler) {
                try {
                    this.waiting = true;
                    await this.waitingHandler();
                    this.waiting = false;
                }
                catch(err) {
                    console.log("CTA Error: ", err);
                }
            }
            else {
                this.$emit("click");
            }
        }
    }

    // watch

    // hooks
}
