
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduLoader from "@/components/commons/EduLoader.vue";

    @Component({
        name: "EduTweets",
    })
    export default class EduTweets extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;
        
        // --- computed
        

        // --- watch
        
        // --- hooks
        mounted() {
            let widgetTwitterScript = document.createElement('script')
            widgetTwitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
            document.head.appendChild(widgetTwitterScript)
        }
    }
