
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import EduTeamMember from "@/components/cards/EduTeamMember.vue";
    import {TeamMember} from "@/types";

    @Component({
        name: "EduTeam",
        components: {EduTeamMember, EduTitle},
    })
    export default class EduTeam extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get team(): Array<TeamMember> {
            const array = [...(this.cms.getData("team") as Array<TeamMember>)];
            let currentIndex = array.length;

            while (currentIndex != 0) {
                const randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }

            return array;
        }

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
