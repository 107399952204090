
import {Component, Vue, Prop} from 'vue-property-decorator';
import {paragraphize} from "@/helpers/commons";
import EduTitle from "@/components/commons/EduTitle.vue";

@Component({
    name: "EduFeatureList",
    components: {EduTitle},
})
export default class EduFeatureList extends Vue {
    // props
    @Prop(Array) features?: Array<any>;
    @Prop(String) title?: string;
    // data

    // computed

    // methods
    featureStyle(feature: any): any {
        return {
            backgroundImage: "url('" + feature.icon + "')"
        };
    }

    featureText(feature: any): string {
        return paragraphize(feature.text)!;
    }

    // watch

    // hooks
}
