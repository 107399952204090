
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import {paragraphize} from "@/helpers/commons";
    import EduTitle from "@/components/commons/EduTitle.vue";

    @Component({
        name: "EduPartners",
        components: {EduTitle},
    })
    export default class EduPartners extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get partners(): Array<any> {
            const partners = this.cms.getData("partners");
            // console.log("partners: ", partners);
            return partners;
        }

        get partnersList(): Array<any> {
            const partners = this.context.partners;
            console.log("partners: ", partners);
            return partners;
        }

        get theText() : string {
            return paragraphize(this.context.text)!;
        }
        
        // --- methods
        
        // --- watch
        
        // --- hooks
    }
