
import { Component, Vue, Prop } from "vue-property-decorator";
import { CMSBaseComp, CMSComponent } from "@snark/cms";
import { paragraphize } from "@/helpers/commons";
import EduModale from "@/components/commons/EduModale.vue";
import EduCta from "@/components/commons/EduCta.vue";

@Component({
  name: "EduHomeModale",
  components: { EduModale, EduCta },
})
export default class EduOnlyModale extends Vue implements CMSBaseComp {
  // --- props
  @Prop(Object) context!: CMSComponent;

  // --- data
  isModalVisible: boolean = false;

  // --- computed
  get text(): string {
    return paragraphize(this.context.texts)!;
  }

  // --- methods
  showModal() {
    this.isModalVisible = true;
    document.body.classList.add("modal-open");
  }
  closeModal() {
    this.isModalVisible = false;
    document.body.classList.remove("modal-open");
  }
  // --- watch

  // --- hooks
  created() {
    const url = new URL(window.location.href);
    const showModalQueryParam = url.searchParams.get("6e");
    if (showModalQueryParam === "edumalin") {
      this.showModal();
    }
  }
}
