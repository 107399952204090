
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "EduModale",
})
export default class EduModale extends Vue {
    // props

    // data

    // computed

    // methods
    close() {
        this.$emit('close');
    }
    // watch

    // hooks
}
