
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import {Testimony} from "@/types";
    import EduTestimoniesLine from "@/components/commons/EduTestimoniesLine.vue";
    import EduCta from "@/components/commons/EduCta.vue";

    @Component({
        name: "EduTestimonies",
        components: {EduCta, EduTestimoniesLine, EduTitle},
    })
    export default class EduTestimonies extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        colorOrders = ["green", "red", "blue"];
        width: number = 0;
        height: number = 0;
        videoShown: boolean = false;
        currentVideo: string|null = null;

        // --- computed
        get lines(): Array<Array<Testimony>> {
            let lines: Array<Array<Testimony>> = [];

            for(let i = 0, n = this.testimonies.length; i < n; i += 3) {
                lines.push(this.testimonies.slice(i, i + 3));
            }

            return lines;
        }

        get mode(): string {
            return this.context.random? "random":"all";
        }

        get testimonies(): Array<Testimony> {
            const testimonies: Array<Testimony> = JSON.parse(JSON.stringify(this.cms.getData("testimonies")));

            if(this.mode === "all") {
                return testimonies;
            }
            else { // if(mode === "random") {
                if(testimonies.length < 3) {
                    return testimonies;
                }

                let rand: Array<Testimony> = [];
                for(let i = 0; i < 3; ++i) {
                    const idx = Math.min(Math.floor(Math.random() * testimonies.length), testimonies.length - 1);
                    const removed = testimonies.splice(idx, 1);
                    rand.push(removed[0]);
                }
                return rand;
            }
        }

        get videoWidth(): number {
            if(this.width) {
                const height = Math.ceil((this.width - 120) / 16 * 9);
                if(height <= this.height - 120) {
                    return this.width - 120;
                }
                else {
                    return Math.floor((this.height - 120) / 9 * 16);
                }
            }
            else {
                return 0;
            }
        }

        get videoHeight(): number {
            if(this.width) {
                const height = Math.ceil((this.width - 120) / 16 * 9);
                if(height > this.height - 120) {
                    return this.height - 120;
                }
                else {
                    return height;
                }
            }
            else {
                return 0;
            }
        }

        // --- methods
        getColorsOrder(idx: number): Array<string> {
            const base = idx % 3;
            return [
                this.colorOrders[base],
                this.colorOrders[(base + 1) % 3],
                this.colorOrders[(base + 2) % 3]
            ];
        }

        showVideo(video: string) {
            console.log("VIDEO: ", video);
            this.currentVideo = video;
            this.videoShown = true;
        }

        hideVideo() {
            this.videoShown = false;
            this.currentVideo = null;
        }

        // --- watch
        
        // --- hooks
        mounted() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    }
