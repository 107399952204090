
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduCta from "@/components/commons/EduCta.vue";
    import {paragraphize} from "@/helpers/commons";

    @Component({
        name: "EduWelcome",
        components: {EduCta},
    })
    export default class EduWelcome extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get pictureStyle(): any {
            return {
                backgroundImage: "url('" + this.context.picture + "')"
            };
        }

        get imageStyle(): any {
            return {
                height: this.context.height + "px"
            };
        }

        get customMarginTop(): any {
            return {
                marginTop: this.context.customMarginTop
            };
        }

        get title(): string {
            return paragraphize(this.context.title)!;
        }

        get text(): string {
            return paragraphize(this.context.text)!;
        }

        get textRight(): string {
            return paragraphize(this.context.textRight)!;
        }

        get mode(): string {
            return this.context.mode? this.context.mode:"";
        }

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
