
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";

    @Component({
        name: "EduCatalogs",
        components: {EduTitle},
    })
    export default class EduCatalogs extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        catalogs = [
            {
                image: "/img/catalogs/catalog-college.jpg",
                name: "Edumalin Collège",
                filename: "/documents/catalogs/Edumalin Collège.pdf"
            },
            {
                image: "/img/catalogs/catalog-lgt.jpg",
                name: "Edumalin LGT",
                filename: "/documents/catalogs/Edumalin LGT.pdf"
            },
            {
                image: "/img/catalogs/catalog-primaire.jpg",
                name: "Edumalin Primaire",
                filename: "/documents/catalogs/Edumalin Primaire.pdf"
            },
            {
                image: "/img/catalogs/catalog-lp.jpg",
                name: "Edumalin LP",
                filename: "/documents/catalogs/Edumalin LP.pdf"
            }
        ];

        // --- computed

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
