
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import EduCta from "@/components/commons/EduCta.vue";

    @Component({
        name: "EduDocs",
        components: {EduCta, EduTitle},
    })
    export default class EduDocs extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get offsetClass(): string|null {
            if(this.context.docs.length === 1) {
                return "gap gap1 col offset-desktop-4 offset-tablet-3 offset-mobile-0";
            }
            else if(this.context.docs.length === 2) {
                return "gap gap2 col offset-desktop-3 offset-tablet-0";
            }
            else {
                return "gap gapmore";
            }
        }

        get docClass(): string {
            return ((this.context.docs.length === 1) || (this.context.docs.length === 3))? "col-desktop-4 col-tablet-6 col-mobile-12":"col-desktop-3 col-tablet-6 col-mobile-12";
        }

        // --- methods
        getTitle(index: number): string {
            return (this.context.titlePrefix? this.context.titlePrefix:"Document") + " n°" + (index + 1);
        }
        
        // --- watch
        
        // --- hooks
    }
