
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Version} from "@/types";
import {paragraphize} from "@/helpers/commons";
import EduCta from "@/components/commons/EduCta.vue";

@Component({
    name: "EduVersion",
    components: {EduCta},
})
export default class EduVersion extends Vue {
    // props
    @Prop(Object) version!: Version;
    @Prop(String) color?: string;

    // data

    // computed
    get text() : string {
        return paragraphize(this.version.texts)!;
    }

    get theColor(): string {
        return this.color? this.color:"red";
    }

    // methods

    // watch

    // hooks
}
