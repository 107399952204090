
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent, DataSet} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import EduCta from "@/components/commons/EduCta.vue";
    import Field from "@/components/commons/field.vue";
    import {Action} from "vuex-class";
    import {sendContact} from "@/store/actions";
    import * as EmailValidator from 'email-validator';
    import {paragraphize} from "@/helpers/commons";

    enum ContactStatus {
        writing = "writing",
        sending = "sending",
        sent = "sent"
    }

    @Component({
        name: "EduContact",
        components: {Field, EduCta, EduTitle},
    })
    export default class EduContact extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        @Action("sendContact") sendContact!: (data: any) => Promise<boolean >;

        // --- data
        form = {
            firstname: "",
            lastname: "",
            email: "",
            subject: "",
            message: ""
        };
        status = ContactStatus.writing;
        errors: DataSet<any> = {email: null, message: null};

        // --- computed
        get formPicture(): any {
            if(this.context.picture) {
                return {
                    backgroundImage: "url('" + this.context.picture + "')"
                };
            }
            else {
                return null;
            }
        }

        get thankYouText(): string {
            return paragraphize(this.context.thankyou.text)!;
        }

        // --- methods
        async submit() {
            console.log("SUBMIT: ", this.form);
            this.status = ContactStatus.sending;

            if(this.verifyForm()) {
                try {
                    const result = await this.sendContact({...this.form, form: this.context._name});
                    console.log("RESULT: ", result);
                    this.status = ContactStatus.sent;
                } catch (err) {
                    console.error("Submit ERROR: ", err);
                    this.status = ContactStatus.writing;
                }
            }
        }

        verifyForm(): boolean {
            let result = true;
            this.errors.message = null;
            this.errors.email = null;

            if(this.form.message.trim().length === 0) {
                this.errors.message = "Vous devez saisir un message.";
                result = false;
            }

            if(!EmailValidator.validate(this.form.email)) {
                this.errors.email = "Votre email ne semble pas valide. Veuillez le vérifier.";
                result = false;
            }

            return result;
        }
        
        // --- watch
        
        // --- hooks
    }
