
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import EduCta from "@/components/commons/EduCta.vue";

    @Component({
        name: "EduEvents",
        components: {EduCta, EduTitle},
    })
    export default class EduEvents extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get mainClass(): string {
            return "EduEvents"
                + ((this.context.events.length === 1)? " onlyone":"");
        }

        get events(): Array<Array<any>> {
            let evs: Array<any>;

            if(typeof this.context.events === "string") {
                evs = this.cms.getData(this.context.events);

                // --- filter old
                if((this.context.old === true) || (this.context.old === false)) {
                    evs = evs.filter(e => {
                        if(this.context.old === true) {
                            return e.old === true;
                        }
                        else {
                            return e.old !== true;
                        }
                    });
                }

                // --- limit count
                if(this.context.count && (evs.length > this.context.count)) {
                    evs = evs.slice(0, this.context.count);
                }
            }
            else if(Array.isArray(this.context.events)) {
                evs = this.context.events;
            }
            else {
                return [];
            }

            let rows = [];
            let row: any;
            for(let i = 0, n = evs.length; i < n; ++i) {
                if(i % 2 == 0) {
                    row = [];
                    rows.push(row);
                }
                row!.push(evs[i]);
            }

            return rows;
        }

        // --- methods
        pictureStyle(event: any): any {
            if(event.picture) {
                return {
                    backgroundImage: "url('" + event.picture.replace(/'/g, "\\'") + "')"
                };
            }
            else {
                return null;
            }
        }

        getCta(event: any): string {
            if(event.cta) {
                return event.cta;
            }
            else {
                if(event.download) {
                    return "Télécharger";
                }
                else if(event.link) {
                    return "Découvrir";
                }
                else {
                    return "En savoir plus";
                }
            }
        }

        // --- watch
        
        // --- hooks
    }
