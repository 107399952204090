
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import {paragraphize} from "@/helpers/commons";

    @Component({
        name: "EduOnlyTitle",
        components: {EduTitle},
    })
    export default class EduOnlyTitle extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get theText(): string {
            return this.context.text? paragraphize(this.context.text)!:"";
        }
        // --- methods
        
        // --- watch
        
        // --- hooks
    }
