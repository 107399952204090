
import {Component, Vue, Prop} from 'vue-property-decorator';
import {paragraphize} from "@/helpers/commons";

@Component({
    name: "EduTitle",
})
export default class EduTitle extends Vue {
    // props
    @Prop(String) title!: string;
    @Prop(String) firstTitle!: string;
    @Prop(String) subtitle?: string;
    @Prop(String) banner?: string;
    @Prop(String) alternativeText?: string;
    @Prop(Boolean) nolimit?: boolean;
    @Prop(Boolean) nomargin?: boolean;

    // data

    // computed
    get sub(): string {
        if(this.subtitle) {
            return paragraphize(this.subtitle)!;
        }
        else {
            return "";
        }
    }

    get mainClass(): string {
        return "EduTitle"
            + (this.nolimit? " nolimit":"")
            + (this.nomargin? " nomargin":"");
    }

    // methods

    // watch

    // hooks
}
