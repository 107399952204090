
import {Component, Vue, Prop} from 'vue-property-decorator';
import {paragraphize} from "@/helpers/commons";
import EduCta from "@/components/commons/EduCta.vue";

@Component({
    name: "EduScreen",
    components: {EduCta},
})
export default class EduScreen extends Vue {
    // props
    @Prop(Object) screen!: any;

    // data

    // computed
    get text(): string {
        return paragraphize(this.screen.text)!;
    }

    get legends(): string {
        return this.screen.legends;
    }

    get plus(): string {
        return paragraphize(this.screen.plus)!;
    }

    get columnCount(): any {
        return {
            columnCount: this.screen.columnCount
        };
    }

    get titleStyle(): any {
        return {
            textAlign: this.screen.titleStyle
        };
    }

    get verticalCenter(): any {
        return {
            alignItems: this.screen.alignItems
        };
    }

    // methods

    // watch

    // hooks
}
