
import {Component, Vue, Prop} from 'vue-property-decorator';

export enum FieldType {
    string = "string",
    text = "text",
    email = "email",
    select = "select"
}

@Component({
    name: "field",
})
export default class field extends Vue {
    // props
    @Prop(String) type!: FieldType;
    @Prop(String) name!: string;
    @Prop(String) label?: string;
    @Prop(String) placeholder?: string;
    @Prop(String) error?: string;
    @Prop() value!: any;
    @Prop(Array) options?: Array<{label: string, value: any}>;
    @Prop(String) emptyOption?: string;
    @Prop(Boolean) mandatory?: boolean;

    // data

    // computed
    get selectOptions(): Array<{label: string, value: any}> {
        if(this.options) {
            if (this.emptyOption) {
                return [
                    {label: this.emptyOption, value: null},
                    ...this.options
                ];
            }
            else {
                return this.options;
            }
        }
        else {
            if(this.emptyOption) {
                return [{label: this.emptyOption, value: null}];
            }
            else {
                return [];
            }
        }
    }

    // methods
    change(data: InputEvent) {
        this.$emit("input", (<any>data.target).value);
    }

    select(data: Event) {
        this.$emit("input", (<any>data.target).value);
    }

    // watch

    // hooks
}
