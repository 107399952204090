import axios from "axios";
import {DataSet} from "@snark/cms";
import {EdumalinActionContext} from "@/store/index";

export async function sendContact({}: EdumalinActionContext, message: any): Promise<boolean> {
    const result = await axios.post(getUrl("/contact"), message);
    return result.data.sent;
}

export async function registerNewsletter({}: EdumalinActionContext, email: string): Promise<boolean> {
    const result = await axios.post(getUrl("/newsletter"), {email});
    return true;
}

export async function getLastTweets({}: EdumalinActionContext): Promise<boolean> {
    const result = await axios.get(getUrl("/tweets"));
    return result.data;
}

// ---------------------------------------------------------------------------------
function getUrl(path: string, queries?: DataSet<any>) : string {
    let url = process.env.VUE_APP_API_URL + path;
    let separator = "?";

    if(queries) {
        Object.entries(queries).forEach(entry => {
            url += separator + entry[0] + "=" + (entry[1]? encodeURIComponent(entry[1].toString()):"");
            separator = "&";
        });
    }

    return url;
}