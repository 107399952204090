
import { Component, Vue, Prop } from "vue-property-decorator";
import { CMSBaseComp, CMSComponent } from "@snark/cms";
import EduTitle from "@/components/commons/EduTitle.vue";
import EduVersion from "@/components/cards/EduVersion.vue";
import { Version } from "@/types";

@Component({
  name: "EduVersions",
  components: { EduVersion, EduTitle },
})
export default class EduVersions extends Vue implements CMSBaseComp {
  // --- props
  @Prop(Object) context!: CMSComponent;

  // --- data
  colors = ["red", "blue", "green"];

  // --- computed
  get versions(): Array<Version> {
    return this.cms.getData("versions");
  }

  // --- methods

  // --- watch

  // --- hooks
}
