import Vue from 'vue'
import App from './App.vue'
import store from './store'
import getConfig from "./config/site-config";
import cms, {getEmptyRouter} from "@snark/cms";
import VModal from 'vue-js-modal';
import VueMatomo from 'vue-matomo';
import moment from "moment-timezone";
moment.tz.setDefault('Europe/France');
moment.locale('fr');

(async function() {
    // --- Router
    const router = getEmptyRouter({
        scroll: {
            smooth: true,
            hashOffset: 79
        }
    });

    // --- CMS
    Vue.use(cms, {
        router,
        store,
        configuration: await getConfig()
    });

    // Modals
    Vue.use(VModal, {
        dynamicDefaults: {
            height: 'auto',
            adaptive: true,
            scrollable: true,
            focusTrap: true,
            classes: "dialog"
        }
    });

    // --- Analytics
    Vue.use(VueMatomo, {
        debug: true,
        disableCookies: true,
        domains: ['localhost'],
        enableLinkTracking: true,
        host: process.env.VUE_APP_MATOMO_URL,
        preInitActions: [],
        requireConsent: false,
        requireCookieConsent: false,
        router,
        siteId: 1,
        trackInitialView: true,
    });

    Vue.config.productionTip = false;

    new Vue({
        router,
        store,
        render: (h: any) => h(App)
    } as never).$mount('#app');
})();
