
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

    @Component({
        name: "EduLoader",
    })
    export default class EduLoader extends Vue {
        // --- props
        @Prop(String) text?: string;

        // --- data
        
        // --- computed
        
        // --- methods
        
        // --- watch
        
        // --- hooks
    }
