
import {Component, Vue, Prop} from 'vue-property-decorator';
import {paragraphize} from "@/helpers/commons";
import EduCta from "@/components/commons/EduCta.vue";

@Component({
    name: "EduCard",
    components: {EduCta},
})
export default class EduCard extends Vue {
    // props
    @Prop(String) title?: string;
    @Prop([String, Array]) texts!: string|Array<string>;
    @Prop(String) color?: string;
    @Prop(String) hilight?: string;
    @Prop(Object) cta?: any;

    // data

    // computed
    get theColor(): string {
        return this.color? this.color:"red";
    }

    get theHilight(): string {
        return this.hilight? this.hilight:"red";
    }

    get theText() : string {
        return paragraphize(this.texts)!;
    }

    // methods

    // watch

    // hooks
}
