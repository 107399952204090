import EduOnlyTitle from "@/components/EduOnlyTitle.vue";
import EduPodcast from "@/components/EduPodcast.vue";
import EduDocs from "@/components/EduDocs.vue";
import EduEvents from "@/components/EduEvents.vue";

export default {
  _path: "/cote-science",
  _layout: "main",
  _pageLayout: "horizontalLayers",
  layers: [
    {
      _component: EduOnlyTitle,
      _name: "support",
      title: "Les capsules scientifiques Edumalin",
      subtitle:
        "Chercheurs, enseignants, membres du CSEN… retrouvez toutes les interviews menées par <b>Alice Latimier</b> dans cette section dédiée à l’information et la formation. Alice est Docteure en psychologie cognitive et spécialiste de l'optimisation de l'apprentissage.",
    },
    {
      _component: EduPodcast,
      _name: "podcast04",
      podcastId: "podcast04",
      title: "Les capsules Edumalin",
      picture: "/img/illus-headphone.svg",
      subtitle:
        "Découvrons une nouvelle série de 5 capsules d’interview en compagnie de <b>Steve Masson</b>, chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
      altTitle: "Interview ",
      podcasts: [
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title: "S.Masson nous parle de ses travaux de recherche.",
          duration: "4'40",
        },
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title:
            "S.Masson nous explique comment stimuler la motivation à apprendre.",
          duration: "8'30",
        },
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title:
            "S.Masson nous montre l'importance de savoir pourquoi on apprend pour rester motivé.",
          duration: "2'20",
        },
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title:
            "S.Masson nous dévoile le pouvoir d'expliquer la plasticité cérébrale aux apprenants pour stimuler leur engagement.",
          duration: "4'40",
        },
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title:
            "S.Masson nous explique les fonctions et bénéfices du feedback dans l'apprentissage.",
          duration: "10'",
        },
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title:
            "S.Masson nous évoque la place de la confiance en soi dans l'apprentissage et explique comment la favoriser.",
          duration: "5'",
        },
        {
          author: "Steve Masson",
          bio: "Chercheur à l'Université du Québec à Montréal et directeur du Laboratoire de Recherche en Neuroéducation",
          title:
            "S.Masson nous explique comment créer des interactions entre la communauté scientifique et la communauté enseignante.",
          duration: "12'30",
        },
      ],
    },
    {
      _component: EduPodcast,
      _name: "podcast01",
      podcastId: "podcast01",
      title: "Les podcasts Edumalin",
      subtitle:
        "Nous inaugurons cette série avec 6 Podcasts de <b>Joëlle Proust</b>, membre du CSEN et Directrice de recherche émérite au CNRS.",
      picture: "/img/illus-micro.svg",
      altTitle: "Podcast ",
      podcasts: [
        {
          author: "J.Proust",
          bio: "membre du Conseil Scientifique de l’Éducation Nationale (CSEN) et Directrice de recherche émérite au CNRS",
          title:
            "J.Proust nous décrit ici ses missions au CSEN dans le groupe « Métacognition et confiance en soi ».",
          duration: "4'",
        },
        {
          author: "J.Proust",
          bio: "membre du Conseil Scientifique de l’Éducation Nationale (CSEN) et Directrice de recherche émérite au CNRS",
          title:
            "J.Proust nous présente les composantes de la boucle de l’autorégulation de l’apprentissage.",
          duration: "4'",
        },
        {
          author: "J.Proust",
          bio: "membre du Conseil Scientifique de l’Éducation Nationale (CSEN) et Directrice de recherche émérite au CNRS",
          title:
            "J.Proust nous parle des gestes pédagogiques qui permettent la mise en place du « Savoir apprendre » et du « Vouloir apprendre ».",
          duration: "6'",
        },
        {
          author: "J.Proust",
          bio: "membre du Conseil Scientifique de l’Éducation Nationale (CSEN) et Directrice de recherche émérite au CNRS",
          title:
            "J.Proust évoque ici les freins à la mise en place de la boucle d’autorégulation des apprentissages.",
          duration: "4'",
        },
        {
          author: "J.Proust",
          bio: "membre du Conseil Scientifique de l’Éducation Nationale (CSEN) et Directrice de recherche émérite au CNRS",
          title:
            "J.Proust définit et explicite la notion de sentiment métacognitif",
          duration: "7'",
        },
        {
          author: "J.Proust",
          bio: "membre du Conseil Scientifique de l’Éducation Nationale (CSEN) et Directrice de recherche émérite au CNRS",
          title: "J.Proust dresse les contours de l’autoévaluation efficace.",
          duration: "4'30",
        },
      ],
    },
    {
      _component: EduPodcast,
      _name: "podcast02",
      podcastId: "podcast02",
      title: "Les capsules Edumalin",
      picture: "/img/illus-headphone.svg",
      subtitle:
        "Découvrons une nouvelle série de 5 capsules d’interview en compagnie d’<b>Irène Altarelli</b>, Maître de Conférences en Psychologie et Neurosciences du Développement à l’Université de Paris et au LaPsyDé.",
      altTitle: "Interview ",
      podcasts: [
        {
          author: "Irène Altarelli",
          bio: "Maître de Conférences en Psychologie et Neurosciences du Développement à l’Université de Paris et au LaPsyDé",
          title:
            "I.Altarelli nous présente ses missions d’enseignement et sur ses travaux de reherche.",
          duration: "3'",
        },
        {
          author: "Irène Altarelli",
          bio: "Maître de Conférences en Psychologie et Neurosciences du Développement à l’Université de Paris et au LaPsyDé",
          title:
            "I.Altarelli nous parle des éventuels bénéfices jeux vidéo d’action pour l’apprentissage.",
          duration: "5'30",
        },
        {
          author: "Irène Altarelli",
          bio: "Maître de Conférences en Psychologie et Neurosciences du Développement à l’Université de Paris et au LaPsyDé",
          title:
            "I.Altarelli décrypte les éventuels effets positifs d’un contexte d’apprentissage ludique.",
          duration: "5'",
        },
        {
          author: "Irène Altarelli",
          bio: "Maître de Conférences en Psychologie et Neurosciences du Développement à l’Université de Paris et au LaPsyDé",
          title:
            "I.Altarelli évoque les aspects bénéfiques du numérique sur l’apprentissage.",
          duration: "6'",
        },
        {
          author: "Irène Altarelli",
          bio: "Maître de Conférences en Psychologie et Neurosciences du Développement à l’Université de Paris et au LaPsyDé",
          title:
            "I.Altarelli. nous parle des liens à tisser entre recherche et éducation",
          duration: "3'",
        },
      ],
    },
    {
      _component: EduPodcast,
      _name: "podcast03",
      podcastId: "podcast03",
      title: "Les capsules Edumalin",
      picture: "/img/illus-micro.svg",
      subtitle:
        "Série de Podcasts de <b>Mathieu Hainselin</b>, Maître de Conférences en Psychologie Expérimentale à l’Université de Picardie.",
      altTitle: "Capsule ",
      podcasts: [
        {
          author: "Mathieu Hainselin",
          bio: "Maître de Conférences en Psychologie Expérimentale à l’Université de Picardie",
          title:
            "Mathieu Hainselin nous parle, dans cette 1ère capsule, de ses fonctions à l’université de Picardie et de ses travaux de recherche sur la mémoire.",
          duration: "5'08",
          video: "https://youtube.com/embed/j3gCyK58gFk",
        },
        {
          author: "Mathieu Hainselin",
          bio: "Maître de Conférences en Psychologie Expérimentale à l’Université de Picardie",
          title:
            "Mathieu Hainselin partage ici ses actions pour valoriser la recherche en sciences cognitives appliquées à l’éducation.",
          duration: "4'",
          video: "https://youtube.com/embed/Mh2D-8h34wc",
        },
        {
          author: "Mathieu Hainselin",
          bio: "Maître de Conférences en Psychologie Expérimentale à l’Université de Picardie",
          title:
            "« Favoriser le désir d’apprendre » : vaste sujet ! Et Mathieu Hainselin donne dans cet extrait des conseils concrets et pratiques pour tenter d’y parvenir.",
          duration: "4'52",
          video: "https://youtube.com/embed/fI1dnXWJMIw",
        },
        {
          author: "Mathieu Hainselin",
          bio: "Maître de Conférences en Psychologie Expérimentale à l’Université de Picardie",
          title:
            "Outil numérique or not outil numérique pour apprendre! Des éclairages de la part de M.Hainselin sur ces réflexions ancrées dans le quotidien des enseignants du XXIème siècle.",
          duration: "5'43",
          video: "https://youtube.com/embed/fh1z8cscvx4",
        },
        {
          author: "Mathieu Hainselin",
          bio: "Maître de Conférences en Psychologie Expérimentale à l’Université de Picardie",
          title:
            "Les contributions de la recherche dans l’évolution des pratiques pédagogiques. Entre principes et illustrations concrètes pour ces 5’ en compagnie de M.Hainselin.",
          duration: "6'50",
          video: "https://youtube.com/embed/li0oX_bEvc0",
        },
      ],
    },
    {
      _component: EduPodcast,
      _name: "podcast05",
      podcastId: "podcast05",
      title: "Les capsules Edumalin",
      picture: "/img/illus-headphone.svg",
      subtitle:
        "Découvrons une nouvelle série de 3 capsules d’interview en compagnie de <b>Benoît Choffin</b>, docteur en informatique (Université Paris-Saclay).",
      altTitle: "Interview ",
      podcasts: [
        {
          author: "Benoît Choffin",
          bio: "Docteur en informatique (Université Paris-Saclay).",
          title:
            "B.Choffin nous parle de ces travaux de recherche sur l’intelligence artificielle appliquée à l’éducation.",
          duration: "4'",
        },
        {
          author: "Benoît Choffin",
          bio: "Docteur en informatique (Université Paris-Saclay).",
          title:
            "B.Choffin nous explique comment l’intelligence artificielle peut améliorer l’apprentissage humain.",
          duration: "5'",
        },
        {
          author: "Benoît Choffin",
          bio: "Docteur en informatique (Université Paris-Saclay).",
          title:
            "B.Choffin nous explique de quelle façon l’apprentissage adaptatif peut soutenir la pédagogie.",
          duration: "5'20",
        },
      ],
    },
    {
      _component: EduPodcast,
      _name: "podcast06",
      podcastId: "podcast06",
      title: "Les capsules Edumalin",
      picture: "/img/illus-micro.svg",
      subtitle:
        "Découvrons une nouvelle série de 6 capsules d’interview en compagnie d'<b>Ange Ansour</b>, co-fondatrice des Savanturiers du CRI.",
      altTitle: "Interview ",
      podcasts: [
        {
          author: "Ange Ansour",
          bio: "Co-fondatrice des Savanturiers du CRI.",
          title:
            "A.Ansour nous présente le programme éducatif des Savanturiers.",
          duration: "3'30",
        },
        {
          author: "Ange Ansour",
          bio: "Co-fondatrice des Savanturiers du CRI.",
          title:
            "A.Ansour nous parle de la posture du « pédagogue-chercheur ».",
          duration: "4'30",
        },
        {
          author: "Ange Ansour",
          bio: "Co-fondatrice des Savanturiers du CRI.",
          title:
            "A.Ansour nous présente l’impact du développement des compétences « pédagogue-chercheur » chez les enseignants impliqués dans un projet Savanturiers.",
          duration: "5'",
        },
        {
          author: "Ange Ansour",
          bio: "Co-fondatrice des Savanturiers du CRI.",
          title:
            "A.Ansour nous parle de l’importance du développement de l’esprit critique chez les élèves.",
          duration: "8'40",
        },
        {
          author: "Ange Ansour",
          bio: "Co-fondatrice des Savanturiers du CRI.",
          title:
            "A.Ansour nous précise l’importance de rester connecté au terrain des enseignants pour mieux comprendre les apports de la recherche à leur pratique pédagogique.",
          duration: "7'",
        },
        {
          author: "Ange Ansour",
          bio: "Co-fondatrice des Savanturiers du CRI.",
          title:
            "A.Ansour nous explique comment il est possible de stimuler la rencontre entre chercheurs et enseignants.",
          duration: "8'",
        },
      ],
    },
    {
      _component: EduPodcast,
      _name: "podcast07",
      podcastId: "podcast07",
      title: "Les capsules Edumalin",
      picture: "/img/illus-headphone.svg",
      subtitle:
        "Découvrons une nouvelle série de 5 capsules d’interview en compagnie de <b>Paul Brazzolotto</b>, docteur en psychologie cognitive et consultant scientifique auprès de l’agence de conseil et de formation Cog’X.",
      altTitle: "Interview ",
      podcasts: [
        {
          author: "Paul Brazzolotto",
          bio: "Docteur en psychologie cognitive et consultant scientifique auprès de l’agence de conseil et de formation Cog’X.",
          title:
            "P.Brazzolotto nous  parle de ses travaux de recherche sur le processus attentionnel et plus précisément, l’impact des interruptions dans nos activités au quotidien, sur l’attention. Il évoque également certaines bonnes pratiques pour protéger notre attention.",
          duration: "5'30",
        },
        {
          author: "Paul Brazzolotto",
          bio: "Docteur en psychologie cognitive et consultant scientifique auprès de l’agence de conseil et de formation Cog’X.",
          title:
            "P.Brazzolotto nous parle de notre attention, comment l'expliquer et dans quelle mesure elle est susceptible d'évoluer au cours de notre vie.",
          duration: "7'30",
        },
        {
          author: "Paul Brazzolotto",
          bio: "Docteur en psychologie cognitive et consultant scientifique auprès de l’agence de conseil et de formation Cog’X.",
          title:
            "P.Brazzolotto nous parle du rôle de l'illusion d'expertise dans notre capacité à faire du multi-tâche.",
          duration: "3'",
        },
        {
          author: "Paul Brazzolotto",
          bio: "Docteur en psychologie cognitive et consultant scientifique auprès de l’agence de conseil et de formation Cog’X.",
          title:
            "P.Brazzolotto nous évoque les leviers qu'il nous est possible d'actionner pour protéger notre attention dans un contexte d'apprentissage.",
          duration: "5'30",
        },
        {
          author: "Paul Brazzolotto",
          bio: "Docteur en psychologie cognitive et consultant scientifique auprès de l’agence de conseil et de formation Cog’X.",
          title:
            "P.Brazzolotto nous évoque les pistes concrètes permettant d'éviter la monotonie dans un contexte d'apprentissage. Il est également question du \"vagabondage de l'esprit\".",
          duration: "6'",
        },
      ],
    },
    {
      _component: EduDocs,
      _name: "interviews",
      title: "Les interviews",
      subtitle:
        "La capsule scientifique c’est aussi des interviews tout aussi passionnantes les unes que les autres.",
      titlePrefix: "Interview",
      cta: "Lire l'interview",
      docs: [
        {
          title:
            "<b>Evaluation formative</b>, différenciation et numérique, vous ne regretterez pas ces quelques minutes en compagnie de <b>Céline Girardet</b>.",
          text: "Céline Girardet est maîtresse assistante dans le groupe de recherche « Évaluation, régulation et différenciation des apprentissages dans les systèmes d'enseignement et de formation » à l’Université de Genève.",
          document: "/doc/interview01.pdf",
          documentName:
            "Edumalin - interview Céline Girardet - Evaluation formative différenciation et numérique.pdf",
        },
        {
          title:
            "<b>Métacognition et enseignement explicite</b> n’auront bientôt plus de secrets pour vous de <b>Jonathan Fernandez</b>.",
          text: "Découvrez sans plus attendre Jonathan Fernandez, Maître de Conférences en Psychologie Cognitive à l’INSPÉ de l’académie de Créteil et spécialiste de l’apprentissage.",
          document: "/doc/interview02.pdf",
          documentName:
            "Edumalin - interview Jonathan Fernandez - Metacognition et enseignement explicite.pdf",
        },
      ],
    },
    {
      _component: EduDocs,
      _name: "breves",
      title: "Nos brèves",
      subtitle:
        "Retrouvez ici nos <b>brèves, réflexions courtes et ciblées</b> sur des thèmes qui nous animent.\n" +
        "N’hésitez pas à nous écrire à l’adresse <u>contact@edumalin.fr</u> des sujets qui pourraient vous intéresser.\n" +
        "Excellente lecture.",
      titlePrefix: "Brève",
      cta: "Lire la brève",
      docs: [
        {
          title:
            "Les <b>sciences de l’apprentissage</b> au service de la <b>pédagogie explicite</b>.",
          document: "/doc/breve01.pdf",
          documentName:
            "Edumalin - Les sciences de l’apprentissage au service de la pédagogie explicite.pdf",
        },
        {
          title:
            "Favoriser la réussite pour maintenir la <b>motivation à apprendre</b>.",
          document: "/doc/breve02.pdf",
          documentName:
            "Favoriser la réussite pour maintenir la motivation à apprendre.pdf",
        },
        {
          title:
            "Guider les élèves avec un retour correctif personnalisé et encourageant.",
          document: "/doc/breve03.pdf",
          documentName:
            "Guider les élèves avec un retour correctif personnalisé et encourageant.pdf",
        },
      ],
    },
    {
      _component: EduEvents,
      _name: "events",
      title: "Nos interventions",
      subtitle:
        "Formations, webconférences, évènements : <b>retrouvez les enregistrements et les supports des interventions animées par Edumalin</b>.",
      events: [
        {
          title:
            "Webconférence en partenariat avec les DANE des Académies de Paris et Bordeaux",
          subtitle:
            "Thème : Faut-il réussir pour être motivé ou être motivé pour réussir ?",
          text: "Notions d’effort, stratégies d’apprentissage efficaces, feedback, intérêt du numérique...",
          document: "/doc/Prez_DANE_juin_Motiver.pdf",
          documentName:
            "Edumalin - Support Webconf DANE Acad Paris et Bordeaux.pdf",
          cta: "Voir le support",
        },
        {
          title: "Séminaire DANE et CARDIE Martinique",
          subtitle:
            "Accompagner le travail de l'élève dans les nouvelles situations d'apprentissage",
          link: "https://tube-outremer.beta.education.fr/videos/watch/c614b955-3834-4d4f-9891-759f449e581f",
          cta: "Revoir",
        },
      ],
    },
  ],
};
