import Vue from 'vue'
import Vuex, {ActionContext} from 'vuex'
import * as actions from "./actions";

Vue.use(Vuex)


export type EdumalinState = {
}

export type EdumalinActionContext = ActionContext<EdumalinState, any>;

export default new Vuex.Store({
    state: {
    },
    mutations: {},
    actions,
    modules: {}
});
