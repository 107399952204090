
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import {paragraphize} from "@/helpers/commons";

    @Component({
        name: "EduExplanation",
        components: {EduTitle},
    })
    export default class EduExplanation extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data

        // --- computed

        // --- methods
        getStepText(step: any): string {
            return paragraphize(step.texts)!;
        }

        getStepParagraphsColor(step: any): string {
            switch(step.color) {
                case "blue":
                    return "red";
                case "green":
                    return "blue";
                case "red":
                default:
                    return "green";
            }
        }
        
        // --- watch
        
        // --- hooks
    }
