
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import {paragraphize} from "@/helpers/commons";
    import EduCta from "@/components/commons/EduCta.vue";

    @Component({
        name: "EduPodcast",
        components: {EduCta},
    })
    export default class EduPodcast extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        width: number = 0;
        height: number = 0;
        videoShown: boolean = false;
        currentVideo: string|null = null;

        // --- computed
        get title() : string {
            return paragraphize(this.context.title)!;
        }

        get subtitle() : string {
            return paragraphize(this.context.subtitle)!;
        }

        get videoWidth(): number {
            if(this.width) {
                const height = Math.ceil((this.width - 120) / 16 * 9);
                if(height <= this.height - 120) {
                    return this.width - 120;
                }
                else {
                    return Math.floor((this.height - 120) / 9 * 16);
                }
            }
            else {
                return 0;
            }
        }

        get videoHeight(): number {
            if(this.width) {
                const height = Math.ceil((this.width - 120) / 16 * 9);
                if(height > this.height - 120) {
                    return this.height - 120;
                }
                else {
                    return height;
                }
            }
            else {
                return 0;
            }
        }

        // --- methods
        getVideoLink(podcast: any, index: number): string {
            return "/podcasts/" + this.context.podcastId + "/" + this.context.podcastId + "_" + (index + 1) + ".mp4";
        }

        getPodcastTitle(podcast: any, index: number): string {
            return (this.context.altTitle? this.context.altTitle:"Podcast") + (index + 1);
        }

        showVideo(video: string) {
            this.currentVideo = video;
            this.videoShown = true;
        }

        hideVideo() {
            this.videoShown = false;
        }

        // --- watch
        // --- hooks
        mounted() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    }
