
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import {paragraphize} from "@/helpers/commons";

    @Component({
        name: "EduPoints",
        components: {EduTitle},
    })
    export default class EduPoints extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get pointsByRow(): number {
            return (this.context.pointsByRow > 0)? this.context.pointsByRow:4;
        }

        get rows(): Array<Array<any>> {
            if(this.context.points) {
                let rows: Array<Array<any>> = [];
                let i = 0;
                while(i < this.context.points.length) {
                    rows.push(this.context.points.slice(i, i + this.pointsByRow));
                    i += this.pointsByRow;
                }
                return rows;
            }
            else {
                return [];
            }
        }

        // --- methods
        pointIconStyle(icon: string): any {
            return {
                backgroundImage: "url('" + icon + "')"
            };
        }

        pointText(text: string|Array<string>): string {
            return paragraphize(text)!;
        }

        pointColor(color: string|null|undefined): string {
            return color? color:"red";
        }
        
        // --- watch
        
        // --- hooks
    }
