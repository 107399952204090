
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import {paragraphize} from "@/helpers/commons";

    @Component({
        name: "EduMiniSimpleCard",
    })
    export default class EduMiniSimpleCard extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get theColor(): string {
            return this.context.color? this.context.color:"red";
        }

        get theText(): string {
            return this.context.text? paragraphize(this.context.text)!:"";
        }

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
